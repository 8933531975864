import React from 'react'
import { Link } from 'react-router-dom'
import style from './faqMenu.module.scss'
import slugify from 'helpers/Slugify.js'

import FaqData from 'components/faq/FaqData'
import FaqHeader from 'components/faq/FaqHeader'
import MetaTags from 'components/MetaTags'
import { ReactComponent as Arrow } from 'assets/images/vector/arrow.svg'

function FaqMenu() {
    const metaData = {
        title: "Frequently Asked Questions | Offshore Bank Accounts"
    }

    const questionCount = 3;

    return (
        <>
            <MetaTags metaData={metaData} />
            <FaqHeader />

            <div className={style.container}>
                {
                    FaqData.map((category, key) =>
                        <div className={style.categoryPanel} key={key}>
                            {category.image && <div className={style.image}>
                                {category.image}
                            </div>}
                            <Link to={'/faq/' + slugify(category.title)} className={style.littleHeader}>
                                {category.title}
                            </Link>
                            <div className={style.questions}>
                                {
                                    category.questions.slice(0, questionCount).map((q, k) =>
                                        <Link to={'/faq/' + slugify(category.title) + '/' + slugify(q.title)} key={k} className={style.question}>
                                            {q.title}
                                        </Link>
                                    )
                                }
                            </div>

                            <Link to={'/faq/' + slugify(category.title)} className={style.link}>
                                <span className={style.viewMore}>View more</span>
                                <Arrow className={style.arrow} />
                            </Link>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default FaqMenu