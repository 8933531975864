import React from 'react';
import style from '../serviceComponent.module.scss'
import ServiceTemplate from 'components/services/serviceTemplate/ServiceTemplate'

function AntiguaBarbuda({data, subData}) {
	const headerRightContent=
	<div>
		<p className={style.paragraph}>
			Imagine living in a lush tropical island with over three hundred immaculate beaches of clear turquoise waters. It almost sounds like paradise, and indeed it is. What is even better is that you can live in this place, considered one of the most beautiful in the world. You are also likely to bump into some of the world's most famous people, such as Oprah Winfrey, Timothy Dalton, Allen Stanford, Giorgio Armani, Richard Branson, and many others who have bought winter homes in the island. While that sounds like a dream, it is possible in the English-speaking Leeward Islands of Antigua and Barbuda through the government's Citizenship by Investment Programme.
		</p>
	</div>

	return <ServiceTemplate data={data} subData={subData} headerRightContent={headerRightContent} leftContent={<LeftContent />} rightContent={<RightContent />}/>
}

function LeftContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>
				Key benefits
			</h2>
			<ul className={style.highlightedList}>
				<li>- One of the cheapest option starting at 100,000 USD</li>
				<li>- Visa free travel to 150+ countries including European Union (Schengen Area), United Kingdom, Singapore and United States of America.</li>
				<li>- Passport is issued within 3-4 months without need to visit a country.</li>
				<li>- An attractive tax regime. There is no income, inheritance or wealth taxes.</li>
			</ul>
		</div>
	)
}

function RightContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>Why choose Antigua and Barbuda?</h2>
			<p className={style.paragraph}>
			One of the top reasons you should choose Antigua and Barbuda is the country's recognition of dual citizenship that enables you to grow your business and enjoy tax reliefs. You get a passport that will give you visa-free travel in over 150 countries, including those within the Schengen Area and the UK. Besides, you get to enjoy the same standard of life as North America and Europe at a 20% lower cost. Other benefits include:
			</p>

			<h2 className={style.littleHeader}>Citizenship Requirements</h2>

			<p className={style.paragraph}>
				If you are the principal applicant, before you make any investment, you must be over 18 years. You will undergo stringent vetting, thorough background checks, and due diligence checks to ensure that you are qualified for citizenship. You will need to have no criminal record and be in good health. The due diligence checks will reveal your financial standing as you need to have dependable investment sources. After you have obtained the first passport, you must reside in the country for five days within the first five years.
			</p>
			<p className={style.paragraph}>
				Antigua and Barbuda has placed restrictions on citizens from Afghanistan, Iran, North Korea, Somalia, Yemen, and Sudan. However, if you are from these countries but migrated before the age of majority to the US, Saudi Arabia, UK, the United Arab Emirates, New Zealand, Canada, or Australia, you may be considered qualified. If you have also maintained permanent residence in those countries for ten or more years, and if you have no economic ties to the restricted countries, your application may be considered.
			</p>

			<h2 className={style.littleHeader}>Investment Options</h2>
			<p className={style.paragraph}>
				Antigua & Barbuda's luscious beauty creates an overreliance on tourism as the primary domestic industry. Realizing this position's fragility, the government introduced the citizenship-by-investment programme to encourage diversification in other sectors such as banking and real estate. Established in 2012, the programme is premised on your ability to make a substantial economic contribution to the country. Once you invest in the islands, you obtain lifetime citizenship for your entire family. The following options are available for you:
			</p>
			<p className={style.paragraphHeader}>
				National Development Fund (NDF) Contribution or Charitable Donation
			</p>
			<p className={style.paragraph}>
				The National Development Fund (NDF) was established in 2006 to fund government projects. By making a non-refundable donation of $100,000 to this fund, you can gain citizenship for a family of four. As such, this is the most pocket-friendly option for high-end individuals.
			</p>

			<p className={style.paragraphHeader}>
				Real Estate
			</p>
			<p className={style.paragraph}>
				You can purchase real estate at a minimum cost of $400,000 from a preapproved project. The government also gives two applicants the option of making a joint investment, each contributing a minimum of $200,000. What is even more appealing is that after five years, you can sell the real estate and still retain your citizenship.
			</p>

			<p className={style.paragraphHeader}>
				The University Fund
			</p>
			<p className={style.paragraph}>
				This option is available for a family of six with an investment of $150,000. Included in this investment are the government and processing fees. Children above 18 years of age but below 30 years are considered dependents, while parents' minimum age is 58 years. What is even more appealing in this offer is the one-year scholarship for one member of the family at the University of West Indies.
			</p>

			<p className={style.paragraphHeader}>
				Business Investment
			</p>
			<p className={style.paragraph}>
				A single person can make an investment of at least $1.5 million. Under this plan, the second option is a joint investment where at least two people invest in a preapproved business, amounting to at least $5 million. Each person is mandated to contribute at least $400,000 to the joint investment. Presently, there is a Limited Time Offer (LTO) running until October 31, 2020, where two applications from related parties are allowed to make a joint investment. Each party is expected to invest a minimum of $200,000 to be eligible.
			</p>
		</div>
	)
}

export default AntiguaBarbuda;