
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import authors from 'components/blog/BlogAuthorData';
import BlogImage from 'components/blog/contentComponents/BlogImage';
export const metadata = {
  author: authors.gediminas,
  headline: "Crypto Taxes",
  date: "2021 03 19",
  slug: "crypto-taxes",
  description: "We describe the four most common tax optimization strategies for cryptocurrency traders depending on their personal situation, trading activity or strategy and market outlook.",
  imageAlt: 'tax office illustration with graphs',
  wideImageStyle: {
    objectPosition: "47%"
  }
};

const layoutProps = {
  metadata
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`As cryptocurrencies are booming more and more, investors and crypto traders are starting to think about optimizing their capital gain and income taxes on their crypto profits.`}</p>
    <p>{`As everyone’s situation is unique, in this blog post we will describe the four most common tax optimization strategies for cryptocurrency traders depending on their personal situation, trading activity or strategy and market outlook.`}</p>
    <BlogImage src={"blog23-1.jpg"} alt="lego bank workers" style={{
      objectPosition: "50% 90%"
    }} mdxType="BlogImage" />
    <h2>{`Structure your investment vehicle`}</h2>
    <p>{`This is one of the best solutions if you are just starting to buy bitcoin, Ethereum, and other cryptocurrencies to build your crypto investment portfolio and especially if you are planning to be an active trader. For example, not just buy and hold, but actively rebalance your portfolio and invest in new different coins and tokens.`}</p>
    <p>{`While the most straightforward option would be to establish an offshore company in a low-tax jurisdiction, in some cases, depending on your home country’s law, it can trigger a `}<a parentName="p" {...{
        "href": "/blog/what-is-controlled-foreign-corporation"
      }}>{`controlled foreign corporation (CFC)`}</a>{` or `}<a parentName="p" {...{
        "href": "/blog/what-is-permanent-establishment"
      }}>{`permanent establishment (PE)`}</a>{` rules by causing the structure to lose its purpose. You can read more about these rules in our previous blog posts.`}</p>
    <p>{`Hence, jurisdictions, which are not treated as high-risk from the tax perspective, for example, the ones with low, but non-zero income tax rates like Cyprus or Malta, or the ones where income tax is only paid when dividends are distributed, like Estonia, are usually the best options to establish such investment companies. `}</p>
    <p>{`Furthermore, you will need to find a crypto-friendly bank and an exchange dealing with companies to start building your portfolio.`}</p>
    <h2>{`Change your tax residency`}</h2>
    <p>{`This option is the best for people who are actively involved in cryptocurrency trading or already have a cryptocurrency portfolio of substantial size and want to start taking the profit in their own name.`}</p>
    <p>{`While tax-wise the best option is to relocate to a low or zero-tax country, similar results depending on your case can be achieved by moving to less exotic and closer to home jurisdictions. This is because not all countries treat cryptocurrency capital gains the same. Even some typically high-tax jurisdictions like Belgium have favorable exemptions for capital gains received by selling cryptocurrencies as part of the management of personal finance, but not as a business activity, can be exempted from the capital gain tax.`}</p>
    <h2>{`Use stablecoins`}</h2>
    <p>{`If your goal is to preserve the value of your portfolio in United States Dollars, Euros, or other fiat currency during the crypto market downturn, stablecoins are one of the best options to do so without affecting your tax bill.`}</p>
    <p><img parentName="p" {...{
        "src": "blog23-2.jpg",
        "alt": "wallet with cash and coins"
      }}></img></p>
    <p>{`While stablecoins are both practically and legally are cryptocurrencies, holding them has the same consequences from the tax perspective as holding cryptocurrencies i.e. in most cases it will be seen as your crypto profits are not yet released and you are not obliged to pay capital gain or income taxes. `}</p>
    <p>{`It is worth mentioning that as stablecoins are not the same as fiat or electronic money, they are exempt from the `}<a parentName="p" {...{
        "href": "/blog/how-does-crs-affect-your-taxes"
      }}>{`Common Reporting Standard (CRS)`}</a>{`. Hence, using stablecoins can save you a lot of time and reduce required paperwork which might be necessary if declaring a traditional bank accounts and deposits.`}</p>
    <h2>{`Take a crypto loan instead of selling`}</h2>
    <p>{`It is one of the best options if you believe that cryptocurrency prices will go up and you do not want to reduce your exposure, but you need some cash for other expenses. As a loan is not an income, because it will have to be repaid, it is a perfect tool to have additional liquidity (cash) and at the same time not increasing your tax bill.`}</p>
    <p>{`This strategy is similar to the one applied by high-net-worth individuals who usually finance their purchases by leveraging their financial assets instead of selling them and facing capital gain or personal income taxes.`}</p>
    <p>{`This option is pretty new as crypto lending has emerged only over the last 2-3 years. However, there is already a number of crypto lending platforms. In general, you can borrow up to 70% of your crypto portfolio value starting at moderate annual interest rates of 6%, but terms depend on cryptocurrencies in your portfolio, on required loan size, and time for how long you are taking the loan.`}</p>
    <p>{`Furthermore, there are even decentralized cryptocurrency lending platforms existing solely on the Ethereum blockchain, so called DeFi lending (DeFi stands for Decentralized Finance).`}</p>
    <p>{`If you are lucky to already own some cryptocurrencies and are looking to release your profit or you are just starting to build your cryptocurrency portfolio, feel free to `}<a parentName="p" {...{
        "href": "/contact"
      }}>{`contact us`}</a>{` for advice. Not only we can advise you on the best option to manage your finance, but also we can help you to setup required entities, assist on relocation and help to open crypto-friendly bank accounts or introduce you to the best cryptocurrency loan brokers.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;