import React from 'react'
import style from './faqHeader.module.scss'

import HeaderTemplate from 'components/templates/HeaderTemplate'
import Search from 'components/search/Search'
import slugify from 'helpers/Slugify'
import FaqData from './FaqData'
import { ReactComponent as FaqImage } from 'assets/images/vector/faq.svg'

function FaqHeader() {
    const contentToSearch = FaqData.map(category => {
        return (
            category.questions.map((question, key) => {
                return (
                    { content: question.title, link: '/faq/' + slugify(category.title) + '/' + slugify(question.title) }
                )
            })
        )
    })

    return <HeaderTemplate leftSideContent={
        <>
            <h1 className={style.headerTitle}>FAQ</h1>
            <FaqImage className={style.headerIcon} />
        </>
    }
        rightSideContent={<Search title={"Search questions"} contentToSearch={[].concat(...contentToSearch)} />} />
}

export default FaqHeader