
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Can I have no tax residency?`}</h1>
    <p>{`Yes, there are plenty of countries with zero or very low taxes, and you can actually move there to save on your tax bill. For example, these countries include United Arab Emirates, Cyprus, Malta, or Panama.`}</p>
    <p>{`However, you should not rely on the advice you get on the internet only. At Offshore Bank Accounts, we have very best tax advisors who can competently analyze your particular case.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;