
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`How is tax residency determined?`}</h1>
    <p>{`Tax residency is determined under the domestic tax laws of each jurisdiction. There might be situations when a person qualifies as a tax resident of more than one jurisdiction. Therefore, they will be considered as a tax resident in more than one jurisdiction.`}</p>
    <p>{`Often, a major determinant of an individual's status as a resident for income tax purposes is whether they are domiciled or maintain an abode and are "present" in the state for 183 days or more (half of the tax year).`}</p>
    <p>{`Other evidence often considered in evaluating whether there has been a permanent change in domicile includes your location of employment, location of the school a family's children attend, memberships in country clubs, social or fraternal organizations.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;