import React from 'react'
import style from './blogEntry.module.scss'
import { useLocation, Link } from 'react-router-dom'
import { MDXProvider } from '@mdx-js/react'

import BlogLinks from 'components/blog/BlogLinks'
import MetaTags from 'components/MetaTags'
import ContentTemplate from 'components/templates/ContentTemplate'
import AuthorSocials from 'components/blog/AuthorSocials'
import BlogStyles from 'components/blog/contentStyles/BlogStyles'
import getBlogContents from 'helpers/blogLoader'
import BlogCarousel from './BlogCarousel'
import { mod } from 'helpers/math'
import { shuffle } from 'helpers/array'
import LazyImage from 'helpers/LazyImage'

// Takes in a BlogContent and displays it's overall content (not overview)
function BlogEntry({ BlogContent, metadata, id }) {
	const location = useLocation();
	const blogContents = getBlogContents();
	const carouselContentArray = shuffle([
		blogContents[mod(id + 1, blogContents.length)],
		blogContents[mod(id + 2, blogContents.length)],
		blogContents[mod(id - 1, blogContents.length)],
		blogContents[mod(id - 2, blogContents.length)],
	]);

	const leftSide =
		<div>
			<h1 className={style.headline}>{metadata.headline}</h1>
			<div className={style.authorSection}>
				<img src={metadata.author.image} className={style.image} alt={metadata.author.name} />
				<p className={style.date}>{metadata.date}</p>
				<Link to={"/author/" + metadata.author.routePath} className={style.author}>
					{metadata.author.name}
				</Link>
				<AuthorSocials authorData={metadata.author} />
			</div>
			<BlogLinks page={location.page} />
		</div>

	return (
		<div>
			<MetaTags blogMeta={metadata} />

			<div className={style.headerImage}>
				<LazyImage src={`blog${metadata.id}-wide.jpg`} alt={metadata.imageAlt} style={metadata.wideImageStyle} placeHolderStyle={{ width: '100%', height: '400px' }} key={metadata.id} />
			</div>
			<ContentTemplate leftSideContent={leftSide} rightSideContent={<MDXProvider components={BlogStyles}><BlogContent /></MDXProvider>} />

			<h3 className={style.header}>Also you may like this</h3>
			<BlogCarousel contentArray={carouselContentArray} />
		</div>
	);
}

export default BlogEntry;
