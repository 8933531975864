
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import authors from 'components/blog/BlogAuthorData';
import BlogImage from 'components/blog/contentComponents/BlogImage';
export const metadata = {
  author: authors.gediminas,
  headline: "El Salvador Residency for Bitcoin Entrepreneurs",
  date: "2021 06 14",
  slug: "el-salvador-residency-for-bitcoin-entrepreneurs",
  description: "El Salvador has recognised Bitcoin as legal tender and its president Nayeb Bukele believes that no capital gain tax incentive will attract crypto entrepreneurs.",
  imageAlt: 'mountain in the horizon',
  metaTags: {
    keywords: "El Salvador Residency for Bitcoin Entrepreneurs"
  }
};

const layoutProps = {
  metadata
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Last week, El Salvador, a country in Central America has legally acknowledged Bitcoin as a legal tender. In other words, El Salvador now treats Bitcoin as one of two local currencies in addition to the United States dollar.`}</p>
    <p>{`According to El Salvador president Nayeb Bukele, because Bitcoin is considered a currency, there are no capital gain taxes for profits released by selling bitcoins. He expects that this move will attract cryptocurrency entrepreneurs and businesses to El Salvador.`}</p>
    <BlogImage src={"blog27-1.jpg"} alt="two people standing near text on pavement" style={{
      objectPosition: "50% 75%"
    }} mdxType="BlogImage" />
    <p>{`However, even if it is true, the tax exemption would only apply for Bitcoin, not for holdings and trading of other cryptocurrencies. Also, El Salvador is not a tax haven. It has capital gain taxes of 10% and personal income tax range from 16% to 28%.`}</p>
    <p>{`On a positive note, there is no `}<a parentName="p" {...{
        "href": "/blog/what-is-controlled-foreign-corporation"
      }}>{`controlled foreign company`}</a>{` (CFC) rules in El Salvador, which means that El Salvador will not automatically tax profits from the companies its residents own in other countries.`}</p>
    <p>{`Overall El Salvador initiative brings great positive news for Bitcoin and other cryptocurrencies and is a brilliant public relations campaign for the country itself. However, the current fiscal benefits of El Salvador for cryptocurrency entrepreneurs are negligible. There are plenty of more competitive residency programs available worldwide.`}</p>
    <p>{`Are you interested in becoming El Salvador or other non or low-tax country resident? Please `}<a parentName="p" {...{
        "href": "/contact"
      }}>{`contact us`}</a>{` as we will recommend the best location given your situation (income streams, geographical preferences, wealth, and other factors).`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;