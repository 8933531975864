const getBlogContents = () => {
  const contents = []
  const context = require.context('assets/blog/', false, /\.mdx$/)

  context.keys().map((fileUrl) => {
    const body = context(fileUrl);
    const extension = '.mdx';
    const id = fileUrl.replace(/^.*[\\\/]/, '').slice(0, -extension.length); // Extracts id from file name
    contents.push({ data: body.default, meta: { ...body.metadata, id: id, dateObject: new Date(body.metadata.date) } })
  });

  return contents.sort((a, b) => b.meta.dateObject - a.meta.dateObject);
}

export default getBlogContents;