
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import authors from 'components/blog/BlogAuthorData';
export const metadata = {
  author: authors.gediminas,
  headline: "Bank Account vs EMI Account: Which One Is Better?",
  date: "2020 04 10",
  slug: "bank-account-or-emi-account",
  description: "For ages only banks were opening payment accounts, now electronic money institutiions have changed the game.",
  imageAlt: 'case full of cash'
};

const layoutProps = {
  metadata
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I’ve noticed a pattern in some of the emails I received from people once we start discussing Electronic Money Institutions (EMIs). In quite a few instances, readers are inclined to believe that they can’t trust their money with any other institution than a bank, which in fact is not true. To clear up the existing misconceptions, in this blog post, I will compare and contrast the differences and similarities between fully licensed Banks and Electronic Money Institutions (EMI).`}</p>
    <p><strong parentName="p">{`Comparing the two`}</strong></p>
    <p>{`One of the few distinctions between the two financial institutions is the fact that EMIs operate almost exclusively online. Some of them happen to have physical open-door premises. Thanks to such business practise, EMIs cut their expenses by not renting or maintaining a physical location, which consequently means that they often can offer excellent banking conditions at lower fees.						`}</p>
    <p>{`Moving on, it is important to note that both EMIs, as well as Banks, are regulated by the government and so are obliged to perform Know Your Customer (KYC) and Anti-Money Laundering (AML) procedures. Unfortunately, I see an increasing amount of complaints about adequate due diligence procedures, which seem to be a byproduct of confusion. 						`}</p>
    <p>{`In all actuality, you ought to be happy about it. As long as your source of income/funds is legitimate or you run a legitimate business, there is nothing to worry about. History has shown the short and disastrous course of financial institutions (including banks) that perform reluctant due diligence. They are shut down, people lose their money, and there’s no one to blame. If you see that the institution is taking compliance seriously, it’s the first sign of trustfulness and longevity.						`}</p>
    <p>{`Don’t get me wrong; there are quite a few rewards when it comes to having an account at a fully licensed bank. Usually, banks offer a wide variety of services such as investment management, merchant banking, wealth planning and structuring, loans, etc. Often I propose my high net worth clients to get an account at a fully licensed bank as in such case it is worth paying extra for the maintenance. However, in reality, every single situation is different and unique in its way; thus, it is always a great idea to talk with a professional. 						`}</p>
    <p><strong parentName="p">{`Finding a reputable institution`}</strong>{`						`}</p>
    <p>{`You can run across a million different EMIs just browsing the web; services of those institutions vary wildly. The crucial thing is finding a reputable institution that you can trust your money with and which you can depend on in a time of an emergency. Too often, people open accounts without familiarizing themselves with the EMIs or its internal policies, and that leads to frozen accounts, rejected transactions, or terrible experiences with compliance teams. A word of advice: if you want to be smart in an unfamiliar situation, be sure to rely on the help of professionals.						`}</p>
    <p>{`If you, after all, decide to look for a bank or an EMI on your own, always do a proper research on the chosen service provider. Check the company's general business information, review its licenses on governmental websites, scan its social media, find out who works in the company, especially who are the top executives. Just follow your common sense. By taking these simple steps, your chances of getting involved with the wrong crowd will reduce immeasurably.
Continuing what is now becoming a tradition, I have selected a couple of initial emails from the cases I had this year.`}</p>
    <h2>{`A case from the public`}</h2>
    <p><strong parentName="p">{`Case #1`}</strong></p>
    <p><strong parentName="p">{`Question:`}</strong>{` I'm dropshipping herbal/vitamins/supplements to customers in Europe. I'm receiving SEPA payments directly from my EU customers. Small tickets 20 to 200, average 50 euro. Annual 500K a year. I currently have HK/Seychelles/Panama corporations. Unfortunately, it is getting harder and harder to set up or maintain bank accounts in Europe. I received notices from my current European banks that they will stop offering services to foreign companies like mine this year. Accounts I used for over ten years. So now I'm looking for alternatives. I'm European but living on another continent. I have addresses in both countries. My requirements are simple. Business account(s) (IBAN) to receive SEPA transfers with no or low fees for incoming SEPA.						`}</p>
    <p><strong parentName="p">{`Our answer:`}</strong>{` To provide you with a tailor-made proposal we would need more information from your side. The most important question is whether your residency country has a CFC rule. Also, it is worth knowing whether the country of residence participates in the CRS system. You mentioned that you receive only SEPA payments, so you don’t use any other payment gateways such as PayPal or Stripe? It would be worth reviewing the whole structure. Most likely, the right EMI would solve your needs.						`}</p>
    <p><strong parentName="p">{`Case #2`}</strong></p>
    <p><strong parentName="p">{`Question:`}</strong>{` We want to put up a company in Nevis, a small investment company that has traders who trade forex and more. I'm Norwegian, and my partners are Scandinavians and British... `}{`[Further text was censored due to the client's personal circumstances.]`}</p>
    <p><strong parentName="p">{`Our answer:`}</strong>{` As a general rule, collecting money from investors and reinvesting it falls under licensing requirements in most of the countries. If you wish, we might help you to set up one of the most cost-effective licensed investment vehicles in the EU: a Lithuanian investment company. It might collect investments from your business partners; all capital gains are exempt from taxation. However, the setup fees would start from 20 – 25 k EUR. In that case, you would have a real licensed investment vehicle. Otherwise, you can use a different model: you can set up a partnership or a company in the EU and investors could become partners/shareholders. Profit distribution model should be prepared accordingly as well. From a legal perspective, such a partnership would work as a joint venture, but not as an investment fund. In any case, these are only preliminary ideas.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;