import React from 'react'
import style from './headerDesktop.module.scss'
import { Link } from 'react-router-dom'

import HeaderLinks from './HeaderLinks'
import SocialMediaLinks from 'components/navigation/socialMediaLinks/SocialMediaLinks'
import { ReactComponent as Logo } from 'assets/images/vector/obclogo.svg'

function HeaderDesktop() {
    return (
        <div className={style.container}>
            <Link to="/">
                <Logo className={style.logo} />
            </Link>
            <div className={style.links}>
                <HeaderLinks />
                <SocialMediaLinks />
            </div>
        </div>
    )
}

export default HeaderDesktop