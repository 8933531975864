
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Do you have to pay taxes on international income?`}</h1>
    <p>{`Taxes on international income depend on the country of your tax residency. For example, US citizens and resident aliens earning a certain amount of income from foreign sources may have to pay income taxes on foreign income. You must pay US taxes on the income you earned abroad in the same way you pay taxes on the income you made in the United States.`}</p>
    <p>{`If you are a non-domiciled resident, the amount of United Kingdom taxes you need to pay on foreign income might depend on whether you bring the money or assets into the country. There are countries, such as the United Arab Emirates or Cyprus, where international income is tax-free.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;