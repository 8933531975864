
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import authors from 'components/blog/BlogAuthorData';
export const metadata = {
  author: authors.gediminas,
  headline: "Back-to-Back Loans",
  date: "2020 08 10",
  slug: "back-to-back-loans",
  description: "While many have heard of back-to-back loans, few people know the many benefits this type of financing entails. In this blog post, we’ll explain just what precisely back-to-back loans are and how you can take advantage of this flexible financing solution.",
  imageAlt: 'woman counting cash'
};

const layoutProps = {
  metadata
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`While many have heard of back-to-back loans, few people know the many benefits this type of financing entails. In this blog post, we’ll explain just what precisely back-to-back loans are and how you can take advantage of this flexible financing solution.`}</p>
    <h2>{`What is a back-to-back loan?`}</h2>
    <p>{`In basic terms, a back-to-back loan is a type of agreement between an entity or individual and a bank, when a bank grants credit against pledged liquid assets up to a certain percentage of their value. The liquid assets may be in the form of equities, bonds or investment funds.`}</p>
    <p>{`A back-to-back loan is also commonly referred to as a lombard loan. The term originated in the Lombardy region in the Middle Ages. Merchants from the region were the first ones to grant collateral loans and laid down the foundation for lending services of today.`}</p>
    <h2>{`Why take out a back-to-back loan?`}</h2>
    <p>{`A back-to-back or lombard loan comes with many benefits. One of the biggest ones – your assets remain invested while you get additional liquidity.`}</p>
    <p>{`Let’s say you have a long-term deposit with favorable rates and you are enjoying your passive income year after year. One day you receive a once-in-a-lifetime offer – Villa in Malibu for the price of 50% below its actual value. If you don’t have liquidity for the purchase, you might be tempted to take back your investment from the bank and go for this offer. However, there’s a way to get your dream house without withdrawing your investment and selling your assets. By taking advantage of back-to-back loans, you can pledge your investment and get the so needed money for this dream house.`}</p>
    <p>{`Apart from bridging personal or business liquidity shortages, a back-to-back loan can be beneficial for diversifying your investments portfolio as well as optimizing your wealth structure in terms of taxes. Additionally, since a back-to-back loan is secured by pledging your assets, which makes this type of loan less risky for the lender as opposed to the typical loan that is not secured, you can also often benefit from lower interest rates.`}</p>
    <p>{`For those who consider applying for a back-to-back loan, it is important to note that leverage-based loans are closely tied to the financial markets and hence are subject to their risks. If you have been approved for the loan, it is possible to obtain cash at any given time. However, this is subject to the bank's terms and you meeting the necessary criteria.`}</p>
    <p><em parentName="p">{`Are you interested in applying for a back-to-back loan or simply want to learn more about this flexible financing solution? Our experienced team is here to answer all your questions and help you find a back-to-back lending solution that fits your particular needs.`}</em></p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;