
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`What is the easiest country to get dual citizenship?`}</h1>
    <p>{`By any means, Vanuatu's second passport is the easiest and most advantageous dual citizenship to get.`}</p>
    <p>{`The timeline for the whole process can take up to 2 months. The citizenship program is open to most nationalities. With a Vanuatu passport, you can travel visa-free to 125 countries, including the Schengen States, UK, Singapore and Hong Kong.`}</p>
    <p>{`Vanuatu's citizenship program requires making an economic contribution to the country's Development Support Program (DSP).`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;