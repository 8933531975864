import React from 'react'
import style from './serviceComponent.module.scss'
import InfoTemplate from 'components/services/serviceTemplate/InfoTemplate'
import ServiceTemplate from 'components/services/serviceTemplate/ServiceTemplate'

function PersonalBankAccountOpening({data}) {
	const headerRightContent=
	<div>
		<p className={style.paragraph}>
			There is a number of reasons why individuals want to open foreign bank accounts. These reasons include diversification, assets protection or access to new financial markets and instruments. The rule of not keeping all eggs in one basket is a rule of thumb in financial world, hence it is smart to keep your money and investments in different jurisdictions to diversify political risk. Also for better investments diversification it is important to have exposure to different markets, hence foreign banks are able to offer an exposure to their niche markets.
		</p>
		<p className={style.paragraph}>
			While most banks are focused for local clients, the best solution for international people an account at an offshore banks. As they specialize in dealing with international clients they can offer wide range of solutions to fit their needs. Furthermore, because of the favorable fiscal climate they are less expensive than alternatives as they have a lower cost base.
		</p>
	</div>

	return <ServiceTemplate data={data} headerRightContent={headerRightContent} rightContent={<RightContent />}/>
}

function RightContent() {
	return (
		<div>
			<h2 className={style.littleHeader}>Our service includes </h2>
			<ul className={style.list}>
				<li>- Recommendation of best-fit financial institutions for your needs, which are likely to accept you</li>
				<li>- Introduction to the bank or another financial institution</li>
				<li>- Assistance to prepare application and collect supporting documents to open an account</li>
				<li>- Support during on-going communication with a bank after account is open </li>
			</ul>
			<InfoTemplate content={
				<div>
					Our prices for personal bank account opening starts from 250 EUR plus bank fees, however if your case is simple we might recommend you a suitable bank free of charge.
				</div>
			}/>
		</div>
	);
}

export default PersonalBankAccountOpening;
