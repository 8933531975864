import React from 'react';
import style from './blogStyles.module.scss';
import BlogLink from 'components/blog/contentComponents/BlogLink';
import BlogImage from 'components/blog/contentComponents/BlogImage';

export default {
  p: props => <p className={style.p} {...props} />,
  h2: props => <h2 className={style.h2} {...props} />,
  a: props => <BlogLink className={style.a} {...props} />,
  img: props => <BlogImage {...props} />,
  ul: props => <ul className={style.ul} {...props} />,
  ol: props => <ol className={style.ul} {...props} />,
  li: props => <li className={style.li} {...props} />
}