
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`What are the advantages of dual citizenship?`}</h1>
    <p>{`Dual citizens are allowed to carry both country passports. For instance, being a US citizen and a citizen of France, you can travel more easily between them. If you have a citizen's passport, you won't need long-stay visas and won't be asked about your trip's purpose during the customs process anymore.`}</p>
    <p>{`Property ownership is limited to citizens in a portion of countries. With dual nationality, you will be entitled to own property in both countries – you can easily live, work, and retire in both.`}</p>
    <p>{`Being a dual citizen allows you to enjoy the benefits and privileges offered by both of your countries. For example, the US's social services may overlap with services offered in your home country — making you eligible for the help twice. You could also be eligible to run for office in both countries, should you be moved to do so.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;