
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Does dual citizenship allow two passports?`}</h1>
    <p>{`People who hold the citizenship of two countries at once are called dual citizens. For example, if you have citizenship in the US and another country, you can simultaneously hold two valid passports.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;