
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`What is an offshore account?`}</h1>
    <p>{`An offshore (or overseas) bank account is an account held in a country you don't reside in. It is very similar to any everyday banking institution. Offshore banks allow you to make and receive payments, hold money, and set up savings or investment accounts in multiple currencies. `}</p>
    <p>{`Offshore accounts can simplify your financial commitments across multiple countries and regions. They can be useful if you need to send or receive regular international payments and transfers.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;