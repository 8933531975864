import React from 'react'
import style from './stillDeciding.module.scss'
import {Link} from 'react-router-dom'

import ServiceData from 'components/services/ServiceData'

function ContactDeciding(){
    return(
        <div className={style.mainContainer}>
            <div className={style.left}>Still Deciding?</div>
            <div className={style.right}>
                <div className={style.littleHeader}>
                    Talk with us about your case
                </div>

                <div className={style.paragraph}>
                    Maybe there is a solution for a problem you have or an opportunity for cost saving in your business setup you have never thought about. Contact us for a free consultation and we will see if we can cooperate. Our offerings include, but are not limited to:
                </div>

                <div className={style.services}>
                    {
                        ServiceData.map((service, key) => {
                            return(
                                <Link 
                                    to={service.link}
                                    className={style.serviceBox}
                                    key={key}
                                >
                                    <div className={style.serviceImage}>
                                        {service.imageDark}
                                    </div>

                                    <div className={style.serviceTitle}>
                                        {service.title}
                                    </div>
                                </Link>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ContactDeciding