
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Are offshore accounts secure?`}</h1>
    <p>{`If you open an offshore account in an economically stable country, offshore banking will prove to be a secure way of managing your finances. On the other hand, offshore accounts are often not protected by the same guarantees compared to domestic banks. `}</p>
    <p>{`In several EU countries, a central government guarantee scheme protects funds up to a certain amount. Such guarantees are particularly beneficial if the bank collapses, as well as in the event of an economic disaster.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;