
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`What does it mean to be a tax resident?`}</h1>
    <p>{`Generally, individuals are considered residents under a tax treaty and are subject to taxation in the country where they maintain their primary place of abode. However, the residence for treaty purposes extends well beyond the narrow scope of the primary area of abode. For instance, many countries also treat persons spending more than a fixed number of days as residents.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;