
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`How to get second citizenship?`}</h1>
    <p>{`There are several ways to get a second citizenship and the corresponding passport, including citizenship by ancestry, citizenship by residence, and citizenship by investment.`}</p>
    <p>{`A portion of countries offers non-residents an opportunity to obtain a second citizenship if their parents were born in the country. For instance, a European country Hungary allows people not living in the country to obtain Hungarian citizenship provided that they can prove the Hungarian citizenship of their parents.`}</p>
    <p>{`Naturalization is the procedure through which a country resident can acquire citizenship. Usually, citizenship can be acquired by permanently residing in the country for a certain amount of time. One of such examples is Canada, whose permanent residents must live in Canada for four out of six years to be eligible for citizenship.`}</p>
    <p>{`Nowadays, more and more countries offer citizenship by investment. This term refers to the process of acquiring second citizenship by investing in the economy of the host country.`}</p>
    <p>{`A married person can also automatically count as a citizen of their partner's country in certain parts of the world. Children may automatically have the parent's nationality as well, even if they were born abroad.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;