
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`What is an offshore company?`}</h1>
    <p>{`An offshore company is incorporated or registered in a jurisdiction that is different from where its shareholders reside. For example, you decide to incorporate a Free Zone Establishment (FZE) to do business in the United Arab Emirates while living in the UK. That would be considered an offshore company. It works as a corporate entity that can trade, hold assets, and conduct normal business activities.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;