
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`What is the Common Reporting Standard (CRS)?`}</h1>
    <p>{`The Common Reporting Standard (CRS) asks jurisdictions and countries to obtain information from their financial institutions and exchange that information with other jurisdictions automatically on an annual basis. `}</p>
    <p>{`CRS requires financial institutions to identify customers' tax residency and report information about foreign tax residents' financial accounts to the local tax authorities. It also involves tax authorities in participating countries to exchange the information with one another.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;