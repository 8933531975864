import React, { useState, useEffect } from 'react'
import style from './blogAuthor.module.scss'
import { Link } from 'react-router-dom'

import MetaTags from 'components/MetaTags'
import getBlogContents from 'helpers/blogLoader'
import AuthorSocials from 'components/blog/AuthorSocials'
import ContentTemplate from 'components/templates/ContentTemplate'
import { ReactComponent as Arrow } from 'assets/images/vector/arrow.svg'
import { ReactComponent as ArrowDown } from 'assets/images/vector/arrow-down.svg'
import LazyImage from 'helpers/LazyImage'

function BlogAuthor({ authorData }) {
    const metaData = {
        title: authorData.name + ` | Offshore Bank Accounts Blog`,
        ogDescription: authorData.bio,
    }

    const blogContents = getBlogContents();

    const amountOfBlogsToLoad = 5;
    const [currentlyShowingPostCount, setCurrentylShowingPostCount] = useState(amountOfBlogsToLoad)
    const [blogContentsToShow, setBlogContentsToShow] = useState(
        blogContents.filter(blog => blog.meta.author === authorData).slice(0, currentlyShowingPostCount)
    )

    useEffect(() => {
        setBlogContentsToShow(
            blogContents.filter(blog => blog.meta.author === authorData).slice(0, currentlyShowingPostCount)
        )
    }, [currentlyShowingPostCount, authorData]);

    const handleShowMorePosts = () => {
        setCurrentylShowingPostCount(currentlyShowingPostCount + amountOfBlogsToLoad)
    };

    const leftSide =
        <>
            <img src={authorData.image} className={style.image} alt="blog author" />
            <h1 className={style.name}>
                {authorData.name}
            </h1>
            <div className={style.bio}>
                {authorData.bio}
            </div>
            <AuthorSocials authorData={authorData} />
        </>

    const rightSide =
        <>
            {
                blogContentsToShow.map((content, key) =>
                    <div key={key}>
                        <AuthorBlogOverview meta={content.meta} />
                    </div>
                )
            }
            {
                (currentlyShowingPostCount < blogContents.filter(blog => blog.meta.author === authorData).length) &&
                <div
                    className={style.loadMore}
                    onClick={handleShowMorePosts}
                >
                    <div>
                        Load more
                    </div>
                    <ArrowDown />
                </div>
            }
        </>

    return (
        <>
            <MetaTags metaData={metaData} />
            <ContentTemplate leftSideContent={leftSide} rightSideContent={rightSide} />
        </>
    )
}

function AuthorBlogOverview({ meta }) {
    return (
        <Link
            to={"/blog/" + meta.slug}
            className={style.overviewContainer}
        >
            <div className={style.overviewImage}>
                <LazyImage src={`blog${meta.id}-big.jpg`} alt={meta.imageAlt} placeHolderStyle={{}} key={meta.id} />
            </div>
            <div className={style.date}>
                {meta.date}
            </div>
            <div className={style.littleHeader}>
                {meta.headline}
            </div>
            <div className={style.paragraph}>
                {meta.description}
            </div>

            <div className={style.readMore}>
                Read more
                <Arrow className={style.arrow} />
            </div>
        </Link>
    )
}

export default BlogAuthor