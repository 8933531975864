import React from 'react';
import style from '../serviceComponent.module.scss'
import ServiceTemplate from 'components/services/serviceTemplate/ServiceTemplate'

function SaintLucia({data, subData}) {
	const headerRightContent=
	<div>
		<p className={style.paragraph}>
			Are you at least 18-years old and above, in excellent health and free of a criminal record? If so, the azure waters, pristine beaches and mesmerising vistas of the majestic island of Saint Lucia are only an investment away from being your home!
		</p>
		<p className={style.paragraph}>
			Since 2015, the Saint Lucian government established one of the Carribean's most affordable Citizenship by Investment (CBI) programme. Applications are reviewed by the Citizenship by Investment Board (CIB). Successful applicants are required to select one of the four investment options. Today, with an investment of just 100,000 USD, you could be a citizen of Saint Lucia within as little as 3 months.
		</p>
	</div>

	return <ServiceTemplate data={data} subData={subData} headerRightContent={headerRightContent} leftContent={<LeftContent />} rightContent={<RightContent />}/>
}

function LeftContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>
				Key benefits
			</h2>
			<ul className={style.highlightedList}>
				<li>-	Visa free travel to more than 140 countries, including the United Kingdom and EU Schengen states. </li>
				<li>-	No tax on wealth, inheritance, capital gains and global income. </li>
				<li>-	No requirement to live in Saint Lucia before, during or after the application process. </li>
			</ul>
		</div>
	)
}

function RightContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>
				Citizenship by Investment Program
			</h2>
			<p className={style.paragraph}>
				The CBI programme is an excellent opportunity to gain a second passport and grant you and your family access to a world of opportunities. This Saint Lucia programme offers:
			</p>
			<ul className={style.list}>
				<li>-	Quick and hassle-free processing via a smooth, clearly defined application system.</li>
				<li>-	A wide variety of investment options. </li>
				<li>-	No requirement to invest until your application is approved. </li>
				<li>-	Allows for dependents — including spouses, parents and children- to be included. </li>
			</ul>

			<h2 className={style.littleHeader}>
				Investment Options
			</h2>

			<p className={style.paragraphHeader}>
				Donation to the National Economic Fund (NEF)
			</p>
			<p className={style.paragraph}>
				Under this option, individual investors can donate a non-refundable 100,000 USD to the government's National Economic Fund (NEF). Investment amounts vary for those with dependents:
			</p>
			<ul className={style.list}>
				<li>-	140,000 USD for main applicant plus spouse; </li>
				<li>-	150,000 USD for main applicant plus 3 dependents e.g. spouse and 2 children;</li>
				<li>-	15,000 USD for each additional dependent beyond initial 3.</li>
			</ul>

			<p className={style.paragraphHeader}>
				Real Estate
			</p>
			<p className={style.paragraph}>
				This option requires a purchase of property approved by the government and worth at least 300,000 USD. The property must be held and maintained for a minimum of 5 years, with any associated fees and taxes fully paid.
			</p>

			<p className={style.paragraphHeader}>
				Non-Interest-Bearing Government Bonds
			</p>
			<p className={style.paragraph}>
   				The minimum investment for this option is 500,000 USD in bonds that do not accrue interest. Should an investor have dependents to include, amounts vary accordingly:
			</p>
			<ul className={style.list}>
				<li>-	535,000 USD for main applicant plus spouse; </li>
				<li>-	550,000 USD for main applicant plus 3 dependents e.g. spouse and 2 children;</li>
				<li>-	15,000 USD for each additional dependent beyond initial 3. </li>
			</ul>

			<p className={style.paragraphHeader}>Enterprise Project</p>
			<p className={style.paragraph}>
				A successful applicant can choose to invest at least 3.5 million USD in a government-approved project that falls under any of these categories:
			</p>
			<ul className={style.list}>
				<li>- Marinas and cruise ports; </li>
				<li>- Agro-processing plants; </li>
				<li>- Bridges, ports, roads and highways;</li>
				<li>- Specialty restaurants; </li>
				<li>- Pharmaceutical products;</li>
				<li>- Research facilities and institutions; </li>
				<li>- Offshore universities.</li>
			</ul>
			<p className={style.paragraph}>
				Should investors wish to pool resources and apply jointly, the minimum amount is 6 million USD, with each investor contributing no less than 1 million USD.
			</p>
		</div>
	)
}

export default SaintLucia;
