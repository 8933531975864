
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import authors from 'components/blog/BlogAuthorData';
export const metadata = {
  author: authors.gediminas,
  headline: "DAC6 explained: What EU Taxpayers Need To Know",
  date: "2021 01 25",
  slug: "dac6-explained",
  description: "Have you heard of the DAC6 mandate? If not, you will definitely want to read on as it can have a significant impact on your taxes. DAC6 might sound new, but it is in fact the sixth amendment to the Directive on Administrative Cooperation, or DAC for short, which has been around since 2011.",
  imageAlt: 'EU flags waving on a building',
  wideImageStyle: {
    objectPosition: "68%"
  }
};

const layoutProps = {
  metadata
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Have you heard of the DAC6 mandate? If not, you will definitely want to read on as it can have a significant impact on your taxes.`}</p>
    <p>{`DAC6 might sound new, but it is in fact the sixth amendment to the Directive on Administrative Cooperation, or DAC for short, which has been around since 2011. `}</p>
    <p>{`Ever since the original directive, the EU has been adding additional mandates and broadening the scope of the original directive.`}</p>
    <p>{`In this article, we will examine the fundamental concepts of DAC as well as the new additions in DAC6.`}</p>
    <p>{`Let's first explore the basics of DAC...`}</p>
    <h2>{`What is DAC?`}</h2>
    <p>{`The directive's primary goal has always been to eliminate tax evasion and loopholes via the exchange of information between EU nations. By freely exchanging information, all jurisdictions can more effectively tax their citizens' income, even if they or their income is in an EU nation different from their residency.`}</p>
    <p><img parentName="p" {...{
        "src": "blog18-1.jpg",
        "alt": "person peeking through a hole"
      }}></img></p>
    <p>{`DAC6 continues this legacy of tightening loopholes via communication between nations.`}</p>
    <p>{`First off, you might wonder, what taxes do the mandates affect? `}</p>
    <p>{`These mandates affect all taxes with a few exceptions, such as VAT tax, customs tax, excise duties, and compulsory social contributions. `}</p>
    <p>{`Additionally, the directives apply to individuals, companies (especially multinational companies), and other legal arrangements, such as trusts or foundations. `}</p>
    <p>{`Since 2011, a few items have been standard. The first directive standardized forms mandated spontaneous exchanges of information, and information by request. `}</p>
    <p>{`We'll explain the different types of information exchange in a moment.`}</p>
    <p>{`The first mandate also defined what information would be required in an exchange. The next mandate, DAC2, added the first version of the automatic exchange of information, which defined the five types of non-financial information that need to be shared between jurisdictions.`}</p>
    <p>{`These categories were and continue to be:`}</p>
    <ul>
      <li parentName="ul">{`Employment income`}</li>
      <li parentName="ul">{`Pension income`}</li>
      <li parentName="ul">{`Directors fees`}</li>
      <li parentName="ul">{`Income `}</li>
      <li parentName="ul">{`Ownership of immovable property`}</li>
      <li parentName="ul">{`Life insurance products`}</li>
    </ul>
    <p>{`DAC2 added another information set called the financial categories. These are: `}</p>
    <ul>
      <li parentName="ul">{`Interests, dividends, and other income generated by a financial account`}</li>
      <li parentName="ul">{`Gross proceeds from sale or redemption`}</li>
      <li parentName="ul">{`Account Balances`}</li>
    </ul>
    <p>{`The previous directive, DAC5, focused on giving tax authorities access to ultimate beneficiary owner information. `}</p>
    <p>{`Now that we know the history and fundamentals of DAC, let's get into each type of information exchange.`}</p>
    <h2>{`The Three Kinds of Information Exchange`}</h2>
    <p>{`First, we have the spontaneous exchange of information which is specifically for cases where tax evasion is suspected. The possible tax evasion can be related to the citizen's country of residence or where they are earning the income. `}</p>
    <p>{`In this case, nations exchange and report information about the `}<a parentName="p" {...{
        "href": "/blog/how-to-pay-lower-taxes"
      }}>{`tax resident`}</a>{` so that they can eliminate any potential tax evasion, prosecute the individuals involved, and properly tax them.`}</p>
    <p>{`The punishments and fines for not reporting or evading are very serious; we do not recommend trying to illegally evade DAC6 or any other tax mandate.`}</p>
    <p>{`Next, an exchange by request occurs when a country requires more information from another country. The country requests the information, receives it, and then proceeds as the relevant tax codes require. Again, the nation requesting can be the citizen's residency or where the income is being earned.`}</p>
    <p>{`Finally, the automatic exchange of information is the most complicated and was broadened further in DAC6. `}</p>
    <p>{`Although the automatic exchange of information was originally a part of DAC1, it has been broadened in DAC6 to include cross-border arrangements and situations. `}</p>
    <p>{`The automatic exchange occurs on an automatic and annual basis when a citizen is active in a country other than their country of residence. In that instance, the jurisdiction that the citizen is active in will automatically exchange information on a periodic basis with the citizen's resident country.`}</p>
    <p>{`The exchanged information includes employment income, pension income, director's fees, income, ownership of immovable property, and life insurance products.`}</p>
    <h2>{`Who Does DAC6 Apply to and What Are Hallmarks?`}</h2>
    <p>{`Another item that DAC6 added was that European intermediaries are now responsible for reporting cross-border agreements that fall under certain "hallmarks." `}</p>
    <p>{`According to DAC6, a European intermediary is "any person that designs, markets, organizes, or makes available for implementation or manages the implementation." As such, this can include European tax advisors, bankers, lawyers, or an individual taxpayer themself if they do not have an advisor.`}</p>
    <p><img parentName="p" {...{
        "src": "blog18-2.jpg",
        "alt": "person in a suit signing a document"
      }}></img></p>
    <p>{`These EU intermediaries are required to report what corporate structures they create for their clients, where they open corporate bank accounts, and more. `}</p>
    <p>{`On the other hand, non-European intermediaries are not obligated to report to tax administrations on the basis of DAC6. By hiring advisors outside of the EU, individuals can maintain their privacy, while staying in compliance with the law. `}</p>
    <p>{`For example, DAC6 does not require our firm, based in the United Arab Emirates, to report to European tax offices.`}</p>
    <p>{`A `}<a parentName="p" {...{
        "href": "https://www.pwc.com/gx/en/tax/newsletters/tax-controversy-dispute-resolution/assets/pwc-tcdr-european-council-DAC6.pdf"
      }}>{`hallmark`}</a>{` is a standard set of characteristics by DAC for determining if a company or individual is possibly using borders and jurisdictions in an attempt to avoid taxes through the use of aggressive tax planning.`}</p>
    <p>{`By making taxpayers and intermediaries responsible for reporting financial information that is involved in cross-border arrangements and through the use of "hallmarks," DAC6 collects more data on its citizens which leads to a decrease in tax avoidance and loopholes.`}</p>
    <p>{`An arrangement may be "cross-border" when it involves one or more Member State or a third outside country, and there are any of the following traits:`}</p>
    <ul>
      <li parentName="ul">{`One or more of the participants in the arrangement performs business activities in another jurisdiction through a permanent establishment located in another jurisdiction`}</li>
      <li parentName="ul">{`One or more of the participants in the arrangement performs an activity in another jurisdiction without being a tax resident or creating `}<a parentName="li" {...{
          "href": "/blog/what-is-permanent-establishment"
        }}>{`permanent establishment`}</a></li>
      <li parentName="ul">{`The arrangement potentially impacts the automatic exchange of information or the identification of beneficial ownership`}</li>
    </ul>
    <h2>{`What is Reportable Under DAC6?`}</h2>
    <p>{`In such cases, DAC6 requires that intermediaries report a lot of information. This includes, but is not limited to:`}</p>
    <ul>
      <li parentName="ul">{`Identification information of the taxpayers and intermediaries involved (including name, date of birth, tax residence, place of birth, and Tax Identification Number)`}</li>
      <li parentName="ul">{`The hallmark that made them reportable`}</li>
      <li parentName="ul">{`A summary arrangement`}</li>
      <li parentName="ul">{`Details of local law`}</li>
      <li parentName="ul">{`The fiscal value of the cross-border arrangement`}</li>
    </ul>
    <p>{`Additionally, according to the `}<a parentName="p" {...{
        "href": "https://ec.europa.eu/taxation_customs/business/tax-cooperation-control/administrative-cooperation/enhanced-administrative-cooperation-field-direct-taxation_en"
      }}>{`official EU website`}</a>{`, the directive also adds the exchange of financial account information, cross-border tax rulings, advance pricing arrangements, country by country reporting, and tax planning schemes to the information exchange.`}</p>
    <p>{`The Organisation for Economic Co-operation and Development’s (OECD) global standards for tax reporting inspired the entire framework of these information exchanges.`}</p>
    <p>{`Even more important is that these directives have legislative force and are not merely recommendations for each nation to follow. In fact, each tax administration must report and provide feedback to each other on the utility of received information, which dramatically increases transparency and efficiency between nations. `}</p>
    <h2>{`Things to Remember About DAC and DAC6`}</h2>
    <p>{`In short, DAC and DAC6 are a part of the trending increase of governments and international organizations to decrease tax evasion by increasing transparency and closing loopholes.`}</p>
    <p>{`Conceptually, DAC is similar to the `}<a parentName="p" {...{
        "href": "/blog/how-does-crs-affect-your-taxes"
      }}>{`common reporting standards`}</a>{` which many nations around the globe follow.`}</p>
    <p>{`As globalization and cooperation between nations increases, it will become more and more difficult to find financial privacy and security, which makes having a tax advisor essential when creating your international tax plans. `}</p>
    <p>{`Whether you are considering changing your tax residency or looking to open an offshore bank account, our team in the United Arab Emirates is ready to assist and advise you in these areas and more. Schedule a `}<a parentName="p" {...{
        "href": "/contact"
      }}>{`consultation`}</a>{` to get started. `}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;