import React from 'react';
import style from './serviceComponent.module.scss'
import ServiceTemplate from 'components/services/serviceTemplate/ServiceTemplate'

function BackToBackLoans({data}) {
	const headerRightContent=
	<div>
		<p className={style.paragraph}>
			In short, a back-to-back loan is a type of a loan in which the entire loan amount is secured by your assets. When you need additional capital or want to take advantage of an investment opportunity, a back-to-back loan can be an efficient financing solution. Instead of selling your securities to get financing and therefore missing out on their upside potential, a back-to-back loan provides you with a possibility to leverage your assets as collateral for a loan.
		</p>
	</div>

	return <ServiceTemplate data={data} headerRightContent={headerRightContent} rightContent={<RightContent />}/>
}

function RightContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>A back-to-back loan can offer quick access to liquidity.</h2>
			<p className={style.paragraph}>
				If you are in need of additional financing. However, to make the most out of this type of loan it is essential to find a trusted lender that can offer the best terms for your particular needs. This is where we can help.
			</p>
		</div>
	)
}

export default BackToBackLoans;
