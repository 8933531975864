import React from 'react'
import style from './cookieConsentFooter.module.scss'
import { Link } from 'react-router-dom'
import CookieConsent from "react-cookie-consent";
import Cookie from 'assets/images/vector/cookie.svg';

function CookieConsentFooter() {
    return (
        <CookieConsent
            enableDeclineButton
            flipButtons
            location="bottom"
            buttonText="Accept"
            cookieName="offshore-consent"
            disableStyles={true}
            containerClasses={style.footerContainer}
            buttonClasses={style.button}
            declineButtonClasses={style.declineButton}
            contentClasses={style.content}
            setDeclineCookie={false}
            declineButtonText="Decline"
            expires={150}
            onAccept={() => {
                window.agreed();
            }}
        >
            <div className={style.textContainer}>
                <img src={Cookie} alt="Cookie" className={style.cookieImage} />
                <div>
                    This website uses cookies to enhance the user experience. For more information about it, please visit our
                    <Link to="/cookies" className={style.link}>
                        <b> Cookie Policy.</b>
                    </Link>
                </div>
            </div>
        </CookieConsent>
    )
}

export default CookieConsentFooter