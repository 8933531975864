import React from 'react';

import { ReactComponent as PersonalBankAccountPicture } from 'assets/images/vector/personal-bank-account.svg';
import { ReactComponent as BusinessBankAccountPicture } from 'assets/images/vector/business-bank-account.svg';
import { ReactComponent as CitizenshipPicture } from 'assets/images/vector/citizenship.svg';
import { ReactComponent as CardsPicture } from 'assets/images/vector/cards.svg';
import { ReactComponent as RegistrationPicture } from 'assets/images/vector/registration.svg';
import { ReactComponent as LoansPicture } from 'assets/images/vector/loans.svg';

import { ReactComponent as PersonalBankAccountPictureDark } from 'assets/images/vector/personal-bank-account-dark.svg';
import { ReactComponent as BusinessBankAccountPictureDark } from 'assets/images/vector/business-bank-account-dark.svg';
import { ReactComponent as CitizenshipPictureDark } from 'assets/images/vector/citizenship-dark.svg';
import { ReactComponent as CardsPictureDark } from 'assets/images/vector/cards-dark.svg';
import { ReactComponent as RegistrationPictureDark } from 'assets/images/vector/registration-dark.svg';
import { ReactComponent as LoansPictureDark } from 'assets/images/vector/loans-dark.svg';

// Service components
import PersonalBankAccountOpening from './serviceComponents/PersonalBankAccountOpening';
import CorporateBankAccountOpening from './serviceComponents/BusinessBankAccountOpening';
import CompanyRegistration from './serviceComponents/CompanyRegistration';
import PremiumCards from "./serviceComponents/PremiumCards";
import BackToBackLoans from "./serviceComponents/BackToBackLoans";
import SecondCitizenship from './serviceComponents/SecondCitizenship';

// Sub services
import SecondCitizenshipAntiguaBarbuda from './serviceComponents/secondCitizenshipCountrySpecific/AntiguaBarbuda'
import SecondCitizenshipBulgaria from './serviceComponents/secondCitizenshipCountrySpecific/Bulgaria'
import SecondCitizenshipDominica from './serviceComponents/secondCitizenshipCountrySpecific/Dominica'
import SecondCitizenshipGrenada from './serviceComponents/secondCitizenshipCountrySpecific/Grenada'
import SecondCitizenshipMalta from './serviceComponents/secondCitizenshipCountrySpecific/Malta'
import SecondCitizenshipMontenegro from './serviceComponents/secondCitizenshipCountrySpecific/Montenegro'
import SecondCitizenshipSaintKittsNevis from './serviceComponents/secondCitizenshipCountrySpecific/SaintKittsNevis'
import SecondCitizenshipSaintLucia from './serviceComponents/secondCitizenshipCountrySpecific/SaintLucia'
import SecondCitizenshipTurkey from './serviceComponents/secondCitizenshipCountrySpecific/Turkey'
import SecondCitizenshipVanuatu from './serviceComponents/secondCitizenshipCountrySpecific/Vanuatu'

const data = [
	{
		title: 'Personal Bank Account Opening',
		link: '/personal-bank-account-opening',
		image: <PersonalBankAccountPicture />,
		imageDark: <PersonalBankAccountPictureDark />,
		component: PersonalBankAccountOpening,
		overview:
		{
			text: 'If you need a bank account for your individual activity or just as safe-heaven to diversify your assets, we can help you to open a personal bank account in several banks, both offshore and onshore.',
		},
		metaData:
		{
			description: "Open a personal offshore bank account. Individual bank account solutions including crypto friendly accounts, investment accounts and residency accounts.",
		}
	},
	{
		title: 'Business Bank Account Opening',
		link: '/business-bank-account-opening',
		image: <BusinessBankAccountPicture />,
		imageDark: <BusinessBankAccountPictureDark />,
		component: CorporateBankAccountOpening,
		overview:
		{
			text: 'Sometimes the crucial part of starting the business is to open a corporate bank account. We can help you to find a bank willing to understand your business model.',
		},
		metaData:
		{
			description: "Open a bank account for holding, trust, foundation, crypto, gambling, Scottish LP and other businesses.",
		}
	},
	{
		title: 'Second Citizenship',
		link: '/second-citizenship',
		image: <CitizenshipPicture />,
		imageDark: <CitizenshipPictureDark />,
		component: SecondCitizenship,
		overview:
		{
			text: 'If you need a second passport to travel visa-free or as an insurance policy for a rainy day, we can advise you on the best solutions for your particular case and help you to obtain a second citizenship.',
		},
		metaData:
		{
			description: "Second passport is an insurance for an international person. Citizenship by investment is available in Bulgaria, Dominica, Malta, St. Kitts, St. Lucia, Vanuatu and other countries.",
		},
		subServices:
			[
				{
					title: 'Antigua and Barbuda',
					altTitle: "Antigua and Barbuda passport",
					link: '/second-citizenship/antigua-barbuda',
					component: SecondCitizenshipAntiguaBarbuda,
					metaData:
					{
						description: "Get Antigua and Barbuda passport via citizenship by investment program. Enjoy visa free travel to 150+ countries including Schengen area, U.K. and Hong Kong",
					}
				},
				{
					title: 'Bulgaria',
					altTitle: "Bulgarian passport",
					link: '/second-citizenship/bulgaria',
					component: SecondCitizenshipBulgaria,
					metaData:
					{
						description: "Get Bulgarian passport via citizenship by investment program. Become European Union citizen and enjoy living and visa free travel in Schengen area.",
					}
				},
				{
					title: 'Dominica',
					altTitle: "Dominica Passport",
					link: '/second-citizenship/dominica',
					component: SecondCitizenshipDominica,
					metaData:
					{
						description: "Get Dominica passport via citizenship by investment program. Enjoy visa free travel to 150+ countries including Schengen area, U.K. and Hong Kong",
					}
				},
				{
					title: 'Grenada',
					altTitle: "Grenada Passport",
					link: '/second-citizenship/grenada',
					component: SecondCitizenshipGrenada,
					metaData:
					{
						description: "Get Grenada passport via citizenship by investment program. Enjoy visa free travel to 150+ countries including Schengen area, U.K. and Hong Kong",
					}
				},
				{
					title: 'Malta',
					altTitle: "Maltese Passport",
					link: '/second-citizenship/malta',
					component: SecondCitizenshipMalta,
					metaData:
					{
						description: "Get Maltese passport via citizenship by investment program. Become European Union citizen and enjoy living and visa free travel in Schengen area",
					}
				},
				{
					title: 'Montenegro',
					altTitle: "Montenegro Passport",
					link: '/second-citizenship/montenegro',
					component: SecondCitizenshipMontenegro,
					metaData:
					{
						description: "Get Montenegro passport via citizenship by investment program in six months. Enjoy visa free travel to more than 115 countries",
					}
				},
				{
					title: 'St. Lucia',
					altTitle: "Saint Lucia Passport",
					link: '/second-citizenship/saint-lucia',
					component: SecondCitizenshipSaintLucia,
					metaData:
					{
						description: "Get St. Lucia passport via citizenship by investment program. Enjoy visa free travel to 140+ countries including Schengen area, U.K. and Hong Kong",
					}
				},
				{
					title: 'St. Kitts & Nevis',
					altTitle: "St. Kitts and Nevis Passport",
					link: '/second-citizenship/saint-kitts-nevis',
					component: SecondCitizenshipSaintKittsNevis,
					metaData:
					{
						description: "Get Saint Kitts and Nevis passport via citizenship by investment program. Enjoy visa free travel to 140+ countries including Schengen area, U.K. and Hong Kong",
					}
				},
				{
					title: 'Turkey',
					altTitle: "Turkey Passport",
					link: '/second-citizenship/turkey',
					component: SecondCitizenshipTurkey,
					metaData:
					{
						description: "Get Turkish passport via citizenship by investment program. Enjoy visa free travel to 125+ countries. No donation required.",
					}
				},
				{
					title: 'Vanuatu',
					altTitle: "Vanuatu Passport",
					link: '/second-citizenship/vanuatu',
					component: SecondCitizenshipVanuatu,
					metaData:
					{
						description: "Get Vanuatu passport via citizenship by investment program. Enjoy visa free travel to 126 countries including Schengen area, U.K. and Hong Kong",
					}
				},
			]
	},
	{
		title: 'Offshore Credit And Debit Cards',
		link: '/offshore-credit-debit-cards',
		image: <CardsPicture />,
		imageDark: <CardsPictureDark />,
		component: PremiumCards,
		overview:
		{
			text: 'Whether you’re on a move or office-bound, the premium and super-premium cards will benefit you and your business.',
		},
		metaData:
		{
			description: "Offshore Credit and Debit Cards. Whether you’re on a move or office-bound, the premium and super-premium cards will benefit you and your business. Designed for those who are looking for exceptional service, the premium and super-premium cards give you unlimited spending power, as well as access to a variety of exclusive events.",
		}
	},
	{
		title: 'Company Registration',
		link: '/company-registration',
		image: <RegistrationPicture />,
		imageDark: <RegistrationPictureDark />,
		component: CompanyRegistration,
		overview:
		{
			text: 'After considering your needs, we can recommend you the best solution for your case and establish a company or set up a whole corporate structure.',
		},
		metaData:
		{
			description: "Open an offshore company. We help incorporate holdings, foundations, trusts, limited partnerships and more.",
		},
	},
	{
		title: 'Back-to-Back Loans',
		link: '/back-to-back-loans',
		image: <LoansPicture />,
		imageDark: <LoansPictureDark />,
		component: BackToBackLoans,
		overview:
		{
			text: 'When you need additional capital or want to take advantage of an investment opportunity, a back-to-back loan can be an efficient financing solution.',
		},
		metaData:
		{
			description: "In short, a back-to-back loan is a type of a loan in which the entire loan amount is secured by your assets. When you need additional capital or want to take advantage of an investment opportunity, a back-to-back loan can be an efficient financing solution. Instead of selling your securities to get financing and therefore missing out on their upside potential, a back-to-back loan provides you with a possibility to leverage your assets as collateral for a loan.",
		}
	}
];

export default data;
