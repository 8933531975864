
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`What are the benefits of an offshore company?`}</h1>
    <p>{`The benefits of a proper offshore company are numerous. It can offer advantages to an existing business or an individual on many levels: offshore asset protection, confidentiality, enhanced privacy, and tax savings. `}</p>
    <p>{`One benefit that is possibly overlooked is the simplicity of setting up an offshore company and maintaining it thereafter. In many offshore jurisdictions, company formation is a quick and seamless process. At Offshore Bank Accounts, we aim to make this process as simple as possible.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;