import React from 'react';
import style from '../serviceComponent.module.scss'
import ServiceTemplate from 'components/services/serviceTemplate/ServiceTemplate'

function Grenada({data, subData}) {
	const headerRightContent=
	<div>
		<p className={style.paragraph}>
			Grenada, commonly known as the Spice Island due to its famous local spices, is without a doubt one of the most attractive waterfronts you can visit in all the Caribbean. The scenic beauty of Grenada is not the only thing that forces people to visit the three gorgeous main islands. There are other factors such as sailing, diving, great restaurants, beautiful beaches, and countless festivities all around the year that makes Grenada a few places on the earth that attracts many affluent investors.
		</p>
	</div>

	return <ServiceTemplate data={data} subData={subData} headerRightContent={headerRightContent} leftContent={<LeftContent />} rightContent={<RightContent />}/>
}
function LeftContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>
				Key benefits
			</h2>
			<ul className={style.highlightedList}>
				<li>-	No requirements related to education or work experience</li>
				<li>-	Citizenship in less than 4 months</li>
				<li>-	Visa free travel to more than 100 countries, including China and Europe's Schengen area</li>
				<li>-	No tax on worldwide income</li>
				<li>-	Cost starts at <b>150,000 USD</b></li>
			</ul>
		</div>
	)
}

function RightContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>
				Grenada Citizenship by Investment Program
			</h2>
			<p className={style.paragraph}>
				Grenada's Citizenship through Investment Program was an initiative launched under the guidelines of the Grenada Citizenship by Investment Act in the year 2013.
			</p>
			<p className={style.paragraph}>
				You should choose Grenada’s Citizenship through Investment Program because of the following reasons:
			</p>
			<ul className={style.list}>
				<li>-	You can include under 26 dependent children and over 65 dependent parents;</li>
				<li>-	According to the E-2 treaty signed between the United States and Grenada, the citizens are allowed to run a significant business in the United States;</li>
				<li>-	During the application process you will not have to travel to Grenada;</li>
			</ul>

			<h2 className={style.littleHeader}>
				Citizenship Requirements
			</h2>
			<p className={style.paragraph}>
				In order to qualify for Grenada’s citizenship, candidates must have:
			</p>
			<ul className={style.list}>
				<li>- an outstanding character</li>
				<li>- no health issues</li>
				<li>- no criminal record</li>
				<li>- financial assets worth more than 1,000,000 USD</li>
			</ul>
			
			<h2 className={style.littleHeader}>
				Investment Options
			</h2>
			<p className={style.paragraph}>
				The investment options according to the Citizenship by Investment Program of Grenada are mentioned below:
			</p>
			<p className={style.paragraphHeader}>
				Single Candidate Donation
			</p>
			<p className={style.paragraph}>
				150,000 USD donation to Grenada National Transformation Fund is required in case of a single candidate. For your convenience, you can transfer the donations in two instalments.
			</p>

			<p className={style.paragraphHeader}>
				Family Application (family of 4: partner and 2 kids under age 18)
			</p>
			<p className={style.paragraph}>
				200,000 USD donation to Grenada National Transformation Fund is required in case of a main candidate, his/her partner and two kids each under the 18 years old. For your convenience, you can transfer the donations in two instalments.
			</p>
			<p className={style.paragraph}>
				<i>An extra dependent is to be supplemental, an additional donation of 25,000 USD is required.</i>
			</p>

			<p className={style.paragraphHeader}>
				Real Estate Investment
			</p>
			<p className={style.paragraph}>
				<i>Mount Cinnamon</i>
			</p>
			<p className={style.paragraph}>
				The facility is located on a luxurious hillside of around two miles of beautiful white sand on the Grand Anse Beach which happens to be one of the most beautiful beaches you can find on the face of the earth. The incredibly elegant resort-hotel, facing towards the capital of St. George’s, is all you want for a relaxed family getaway on the beach.
			</p>
			<p className={style.paragraph}>
				Applicants are required to invest at least 350,000 USD for a family of up to four (partner + two dependents). If applicants want to bring in extra dependents, then an extra 25,000 USD must be paid for each additional dependent.
			</p>
			<p className={style.paragraph}>
				Mount Cinnamon will cover the following costs (only for the applicant and their spouse): investment fee, due diligence, application and government fees, local escrow fees, Oath of Allegiance and passport fees, and VAT & bank charges of up to 3,500 USD. The dependents will have to pay the fees and applicable charges mentioned above.
			</p>
			<p className={style.paragraph}>
				The golden opportunity to purchase shares of Mount Cinnamon is an open invitation to become a citizen of Grenada, build the foundation of a long relationship, and contribute to the future of the island.
			</p>
		</div>
	)
}

export default Grenada;
