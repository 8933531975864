import React from 'react'
import style from './blogCarousel.module.scss'
import { Link } from 'react-router-dom'
import Slider from "react-slick"

import BlogOverview from './BlogOverview'
import getBlogContents from 'helpers/blogLoader'

import { ReactComponent as RightArrow } from 'assets/images/vector/arrow-right.svg'
import { ReactComponent as LeftArrow } from 'assets/images/vector/arrow-left.svg'
import { ReactComponent as Arrow } from 'assets/images/vector/arrow.svg'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function BlogCarousel({ contentArray }) {
	const blogContents = getBlogContents();
	const slideCount = 5;

	let slides = null;

	if (contentArray) {
		slides = contentArray.map((blog, i) => (
			<BlogOverview className={style.slide} key={i} meta={blog.meta} />
		))
	} else {
		slides = blogContents.slice(0, slideCount).map((blog, i) => (
			<BlogOverview className={style.slide} key={i} meta={blog.meta} />
		))
	}

	var settings = {
		dots: true,
		dotsClass: "slick-dots " + style.dots,
		arrows: true,
		infinite: true,
		lazyLoad: "progressive",
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		nextArrow: <NextArrow />,
		prevArrow: <PreviousArrow />,
		responsive: [
			{
				breakpoint: 1560,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
				}
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					arrows: false
				}
			}
		]
	};

	function NextArrow(props) {
		const { onClick, className } = props;
		return <RightArrow onClick={onClick} className={className + ' ' + style.arrow} />
	}

	function PreviousArrow(props) {
		const { onClick, className } = props;
		return <LeftArrow onClick={onClick} className={className + ' ' + style.arrow} />
	}

	return (
		slides != null && slides.length > 0 &&
		<div className={style.carousel}>
			<Slider {...settings} className={style.slider}>
				{slides}
			</Slider>

			<Link
				to={"/blog"}
				className={style.link}
			>
				Read more articles
				<Arrow className={style.linkArrow} />
			</Link>
		</div>
	)
}


export default BlogCarousel;
