// This is the component which lists all of the blog overviews (on which you can click on and proceed to the full blog entry)

import React, { useState, useEffect } from 'react'
import style from './blogMenu.module.scss'
import { useLocation } from 'react-router-dom'

import Pagination from 'components/blog/Pagination'
import getBlogContents from 'helpers/blogLoader'
import BlogOverview from 'components/blog/BlogOverview'
import MetaTags from 'components/MetaTags'
import Search from 'components/search/Search'
import HeaderTemplate from 'components/templates/HeaderTemplate'

import { ReactComponent as Icon } from 'assets/images/vector/blog.svg'

function BlogMenu() {
	const location = useLocation();
	const blogContents = getBlogContents();

	const [currentPage, setCurrentPage] = useState(location.page ? location.page : 1)
	const entriesPerPage = 6;

	const indexOfLastPost = currentPage * entriesPerPage
	const indexOfFirstPost = indexOfLastPost - entriesPerPage

	const paginate = pageNumber => setCurrentPage(pageNumber);

	const contentToSearch = blogContents.map(blog => {
		return { content: blog.meta.headline, link: "/blog/" + blog.meta.slug }
	})

	const metaData = {
		title: "Blog | Offshore Bank Accounts",
		ogDescription: "Opening offshore bank accounts for businesses and individuals",
	};

	const headerLeftSide =
		<h1 className={style.header}>Blog
			<Icon className={style.icon} />
		</h1>


	useEffect(() => {
		window.scrollTo(0, 0);
	}, [currentPage]);

	return (
		<div>
			<MetaTags metaData={metaData} />
			<HeaderTemplate leftSideContent={headerLeftSide} rightSideContent={<Search title={"Search blogs"} contentToSearch={contentToSearch} />} />

			<div className={style.container}>
				{
					// Reverse the contents to supply the most recent blog entries first		
					blogContents.slice(indexOfFirstPost, indexOfLastPost).map((c, k) =>
						<BlogOverview meta={c.meta} key={k} page={currentPage} />
					)
				}
			</div>
			<div className={style.pageSelection}>
				<Pagination entriesPerPage={entriesPerPage}
					totalEntries={blogContents.length}
					currentPage={currentPage}
					paginate={paginate}
				/>
			</div>
		</div>
	);
}

export default BlogMenu;
