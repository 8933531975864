
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import authors from 'components/blog/BlogAuthorData';
export const metadata = {
  author: authors.luigi,
  headline: "Differences Between DLT and Blockchain",
  date: "2021 06 09",
  slug: "differences-between-dlt-and-blockchain",
  description: "Distributed Ledger Technology (DLT) and blockchain technology are trending topics. Understand the different between the two to determine which is best for your needs.",
  imageAlt: 'glass sphere building',
  wideImageStyle: {
    objectPosition: "75%"
  },
  metaTags: {
    title: "Understanding the Differences Between DLT and Blockchain Technology | Offshore Bank Accounts Blog",
    description: "Distributed Ledger Technology (DLT) and blockchain technology are trending topics. Understand the different between the two to determine which is best for your needs."
  }
};

const layoutProps = {
  metadata
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Distributed Ledger Technology (DLT) and blockchain technology are designed to serve many of the same functions. It may be difficult for those who don’t work closely with one or the other to wonder if there’s any real difference.`}</p>
    <p>{`To be sure, both technologies do have a lot in common, but it’s important to understand how each one works. Here are some basics that will help, including a few of the more significant differences.`}</p>
    <p><img parentName="p" {...{
        "src": "blog26-1.jpg",
        "alt": "code on a monitor"
      }}></img></p>
    <h2>{`What is Distributed Ledger Technology?`}</h2>
    <p>{`DLT is essentially a database that may be provisioned or distributed over more than one location. Several people may manage each of those allocations, which helps to keep tampering with the data more difficult to accomplish and even harder to hide.`}</p>
    <p>{`Given how the data is distributed, the steps needed to completely alter, delete, or otherwise make unauthorized use of that data are extremely difficult. This is an improvement over the way banks and other financial entities managed data in times past. DLT protects the information with greater ease while also ensuring that those who should have access can do so when and as needed.`}</p>
    <h2>{`What is Blockchain?`}</h2>
    <p>{`There’s a saying among professionals that goes something like this: All blockchains are DLT but not all DLT are blockchains. That’s because blockchain is considered to be a form of DLT while not being the only form.`}</p>
    <p>{`With blockchain, you are still dealing with what amounts to a database. The difference is that the data is organized into a series of blocks or modules. Each block can store a finite amount of data. When the most recent block is full, another block is added to the chain.`}</p>
    <h2>{`Centralized Versus Decentralized Performance`}</h2>
    <p>{`Another area that may seem confusing has to do with centralized versus decentralized performance and use. Both blockchain and DLT can be thought of as decentralized. In the case of DLT, decisions about data are decided by a review of the peers who utilize it. That includes what is stored where and who has access to it.`}</p>
    <p>{`So far, that sounds somewhat like blockchain technology. Here’s where there’s one slight difference: both are usually decentralized, but DLT does allow for the possibility of having a central figure who is granted some degree of control over the entire network. How much control is given to that central figure can vary. By contrast, this does not happen with blockchain, with all users being considered peers.`}</p>
    <h2>{`The Role of Cryptic Validation`}</h2>
    <p>{`Some DLT setups do not require permissions to access certain types of data. Depending on the setting, no kind of validation will be needed at all. The data is readily accessible by anyone who chooses to seek it. Other DLT setups are considered hybrids; some information is open while other data require cryptic validation. Still, others need validation to access any of the data.`}</p>
    <p><img parentName="p" {...{
        "src": "blog26-2.jpg",
        "alt": "cement blocks"
      }}></img></p>
    <p>{`With blockchain, cryptic validation exists from one block to the next. Some networks are set up so that users can only migrate from one block to the next in the sequence, depending on the encryption used. Even the formation of a new block within the chain must be approved by a majority of the network users.`}</p>
    <h2>{`Transparency`}</h2>
    <p>{`There’s a relatively high degree of transparency associated with blockchain technology. Essentially, any user can review the history of a transaction from beginning to end.`}</p>
    <p>{`It’s also possible to check the sequence of actions leading up to that transaction and then review relevant actions that led to the next transaction. This is helpful with transactions related to the buying and selling of cryptocurrency, as it's easy to see how many times it changed hands in the past.`}</p>
    <p>{`With DLT, there’s still a high level of transparency. The difference is who can access the data and review all the steps associated with the transaction. The credentials held by the user determine who has authority to review all the details related to specific transactions and who can review all types of transactions.`}</p>
    <h2>{`Recording Details of Transactions`}</h2>
    <p>{`Both solutions make it possible to create and store complete histories of any transaction. It’s not limited to electronic funds transfers between checking and savings accounts, moving money from checking or savings into a time deposit account, or making a mortgage payment using funds in a checking account. It’s also about transactions between banks, like moving money from domestic accounts to `}<a parentName="p" {...{
        "href": "/blog/why-you-might-need-an-offshore-bank-account"
      }}>{`offshore bank accounts`}</a>{`.`}</p>
    <p>{`As noted previously, the data stored in a blockchain is arranged in a series of groups or modules connected within a progressive sequence. By contrast, DLT is more of what people would consider a traditional database that allows for accessing the details according to the sequence. While they differ in structure, both provide a means of securely recording all the steps associated with a transaction.`}</p>
    <h2>{`Streamlining Costs`}</h2>
    <p>{`The cost of conducting transactions and creating histories is one area where there tends to be more of a difference between the two database types. With DLT, there is likely to be more analog work involved. In other words, more of the steps needed to manage a transaction and record the details may be manual. This can increase the overall cost of carrying out a transaction.`}</p>
    <p><img parentName="p" {...{
        "src": "blog26-3.jpg",
        "alt": "inside of a car in front of a shop"
      }}></img></p>
    <p>{`With blockchain, a more significant number of the steps involved are automated. This can save time in terms of processing the transaction. `}<a parentName="p" {...{
        "href": "/blog/bank-account-or-emi-account"
      }}>{`Financial institutions`}</a>{` will also find that blockchains help reduce the amount of physical labor needed to ensure all transactions are completed.`}</p>
    <p>{`For consumers, this can mean funds transfers within the same institution may be finished sooner. Even in transactions between institutions, the amount of time needed to complete them could be reduced significantly.`}</p>
    <h2>{`Summary`}</h2>
    <p>{`There is more to know about each of these solutions, but keep in mind that both have a great deal to offer. Bank customers and investors should understand that both approaches provide a level of security and protection of proprietary data that are greater than the solutions offered only a few decades ago.`}</p>
    <p>{`Also, keep in mind that the evolving nature of blockchain is likely to make it more attractive to consumers of all types in the future rather than being as closely associated with cryptocurrency purchases and trades.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;