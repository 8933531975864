
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`How to get a crypto exchange license?`}</h1>
    <p>{`Many different jurisdictions offer a cryptocurrency exchange license. To illustrate the cryptocurrency company formation process, we will choose Estonia as an example.`}</p>
    <p>{`To carry out the cryptocurrency company formation, you will need to register your business in Estonia. First, choose a company name. It will be verified for availability shortly thereafter. Then, the company must be registered with the Estonian Commercial Register and the VAT authorities.`}</p>
    <p>{`You must then file your application with the Financial Supervisory Authority through the Estonian Financial Intelligence Unit. The documents required for submission are the company's Certificate of Incorporation, Know Your Customer and risk assessment documentation. Your application will be reviewed by the Intelligence Unit of Estonia.`}</p>
    <p>{`The process takes up to 30 days. After that, the company can commence its activities. It usually takes about two weeks for a cryptocurrency license to be issued. It is worth noting that the company must start its operations within six months from when the license was issued.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;