import React from 'react';
import style from '../serviceComponent.module.scss'
import ServiceTemplate from 'components/services/serviceTemplate/ServiceTemplate'

function SaintKittsNevis({data, subData}) {
	const headerRightContent=
	<div>
		<p className={style.paragraph}>
			The citizenship-by-investment programme in St. Kitts & Nevis is the oldest of its kind, dating back to its inception in 1984. This stunning two-island country is blessed with beautiful warm skies, sandy beaches, and a buzzing tourism and trade economy.
		</p>
		<p className={style.paragraph}>
			St. Kitts and Nevis could be your home in as few as six months upon meeting its citizenship by investment programme requirements.
		</p>
	</div>

	return <ServiceTemplate data={data} subData={subData} headerRightContent={headerRightContent} leftContent={<LeftContent />} rightContent={<RightContent />}/>
}

function LeftContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>
				Key benefits
			</h2>
			<ul className={style.highlightedList}>
				<li>-	Citizenship in 4 months</li>
				<li>-	No residency requirements</li>
				<li>-	Visa free travel to more than 135 countries including United Kingdom and Schengen area (European Union)</li>
				<li>-	No taxes on worldwide income or wealth</li>
				<li>-	Cost starts at <b>150,000 USD</b></li>
			</ul>
		</div>
	)
}

function RightContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>
				Citizenship by Investment Program
			</h2>
			<p className={style.paragraph}>
				St. Kitts & Nevis has a unique landscape and stunning physical features that attract numerous tourists across the world. Besides, the economic prowess of this country promises exceptional growth in the coming years as it has already been set on an upward trajectory.
			</p>
			<p className={style.paragraph}>
				Accessing the island is possible via direct flights from the US and Europe, and its passport will allow you access to over 100 countries visa-free. The following are among the key citizenship benefits available to applicants via the citizenship-by-investment strategy.
			</p>
			<ul className={style.list}>
				<li>-	Timely and straightforward passport processing that takes up to four months. Fast track application in 60 days.</li>
				<li>-	Easy second citizenship and passport for family members, including dependent children or parents.</li>
				<li>-	You will be given lifetime citizenship.</li>
				<li>-	No obligation to visit to St. Kitts & Nevis for application purpose.</li>
				<li>-	Flexible investment options per your choice or real estate investment. </li>
			</ul>
			<p className={style.paragraph}>
				Due to these benefits, this programme has attracted numerous immigrants, but St. Kitts & Nevis has only approved a few. The passport of St. Kitts & Nevis has gained a high reputation and highly regarded across UK, US, Singapore, Hong Kong, and Schengen member states.
			</p>

			<h2 className={style.littleHeader}>
				Citizenship Requirements
			</h2>
			<p className={style.paragraph}>
				The St. Kitts & Nevis’ citizenship-by-investment programme was established via the 1984 Citizenship Act, Part II, Section 3 (5). This regulation requires all immigrants willing to acquire the citizenship of this country to meet the following key requirements:
			</p>
			<ul className={style.list}>
				<li>- Have a crime-free criminal record. </li>
				<li>- Be a person of high integrity and exceptional character.</li>
				<li>- Have a superb personal health record.</li>
				<li>- Have a substantial net worth. </li>
			</ul>

			<h2 className={style.littleHeader}>Investment Options</h2>
			<p className={style.paragraph}>
				The investment programmes are highly flexible, and interested immigrants have the following investment options.
			</p>

			<p className={style.paragraphHeader}>
				Sustainable Growth Fund Donation
			</p>
			<p className={style.paragraph}>
				The following are the contribution levels that need to be met based on the number of applicants.
			</p>
			<ul className={style.list}>
				<li>-	150,000 USD for the main applicant;</li>
				<li>-	25,000 USD for the applicant's partner</li>
				<li>-	10,000 USD for each additional dependent (kids, parents, grandparents)</li>
			</ul>
			<p className={style.paragraph}>
				In addition there are due dilligence fees:
			</p>
			<ul className={style.list}>
				<li>-	7,500 USD for the main applicant</li>
				<li>-	4,000 USD for a patner or dependent over 16</li>
				<li>-	4,000 USD for the financial sponsor</li>
			</ul>

			<p className={style.paragraphHeader}>
				Real Estate Investments
			</p>
			<p className={style.paragraph}>
				The applicants are required to invest at least 400,000 USD in government-approved real estate projects or shares in these projects. The property can be resold after five years, during which the owner may be bound to pay additional costs or taxes.
			</p>
			<p className={style.paragraph}>
				The second option in real estate investment involves making a 200,000 USD investment in luxury resorts backed by the government. This requires the immigrant to maintain the property for seven years.
			</p>
			<p className={style.paragraph}>
				<i>Kittitian Hill</i>
			</p>
			<p className={style.paragraph}>
				These are premium cottage four-bedroom suites in the Kittitian Hill resort. Immigrants using this as their entry ticket to St. Kitts & Nevis are obligated to buy shares in this resort. This investment option presents massive benefits, including access and using the resort for 14 nights per year. You are not obligated to pay ongoing costs in the next 5 years, and a privilege to enroll in your preferred residence.
			</p>

			<p className={style.paragraph}>
				<i>Koi Resort and Residences</i>
			</p>
			<p className={style.paragraph}>
				This is an alternative real estate investment option that features word-class luxury suites, villas, and an ocean view. This investment gives you the choice of purchasing a whole unit or buying based on fractional interest.
			</p>

			<p className={style.paragraph}>
				<i>Golden Rock</i>
			</p>
			<p className={style.paragraph}>
				This is an intensified land development area of about six acres. This commercial park is located near the airport and consists of numerous multi-storied buildings in a serene environment. This mode of investment is a solid choice for immigrants seeking to enjoy their life in the peaceful and quiet side of St. Kitts & Nevis.
			</p>
		</div>
	)
}

export default SaintKittsNevis;
