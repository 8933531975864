
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Can I live in Country A and pay taxes in Country B?`}</h1>
    <p>{`If you are not considered a resident in Country A, you will not have to pay taxes on your foreign income. If you are residing in Country A, you will typically pay taxes on your foreign income. However, you may not have to do it if your permanent home ('domicile') is abroad.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;