import React from 'react'
import style from './contactMessages.module.scss'

import { ReactComponent as LoadingIcon } from 'assets/images/vector/loading.svg'
import { ReactComponent as SuccessIcon } from 'assets/images/vector/success.svg'
import { ReactComponent as FailureIcon } from 'assets/images/vector/failure.svg'

function LoadingMessage() {
    return (
        <div className={style.loading}>
            <LoadingIcon className={style.loadingIcon} />
            <div className={style.text}>
                Please wait...
            </div>
        </div>
    )
}

function SuccessMessage() {
    return (
        <div className={style.success}>
            <SuccessIcon className={style.icon} />
            <div className={style.text}>
                Thank you for contacting us. We will come back shortly!
            </div>
        </div>
    )
}

function FailureMessage() {
    return (
        <div className={style.failure}>
            <FailureIcon className={style.icon} />
            <div className={style.text}>
                Something went wrong. Please try again later.
            </div>
        </div>
    )
}

export { LoadingMessage, SuccessMessage, FailureMessage }