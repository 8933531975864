
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`What is the Permanent Establishment (PE)?`}</h1>
    <p>{`Permanent establishment (PE) can be regarded as a tax concept that is often included in trade agreements and varies from country to country. Generally, it means that a tax authority considers a business as having an ongoing presence in the country. Therefore, the business is subject to corporate taxes and, possibly, VAT.`}</p>
    <p>{`A company deemed to have a permanent establishment will be subject to every tax it would pay for profits generated in the country. In such case, the taxes are calculated according to the local tax rates.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;