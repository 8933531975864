import React from 'react';
import style from './authorSocials.module.scss';
import { ReactComponent as Facebook } from 'assets/images/vector/facebook-border.svg'
import { ReactComponent as LinkedIn } from 'assets/images/vector/linked-in-border.svg'
import { ReactComponent as Twitter } from 'assets/images/vector/twitter-border.svg'

const AuthorSocials = ({ authorData }) => {
    return (
        <div className={style.socials}>
            {authorData.socials.facebook && <a href={authorData.socials.facebook} rel="noopener noreferrer" className={style.social} target={"_blank"}><Facebook /></a>}
            {authorData.socials.linkedin && <a href={authorData.socials.linkedin} rel="noopener noreferrer" className={style.social} target={"_blank"}><LinkedIn /></a>}
            {authorData.socials.twitter && <a href={authorData.socials.twitter} rel="noopener noreferrer" className={style.social} target={"_blank"}><Twitter /></a>}
        </div>
    )
}

export default AuthorSocials;