import React from 'react'
import { ReactComponent as PersonalBankAccountPicture } from 'assets/images/vector/personal-bank-account.svg';
import { ReactComponent as BusinessBankAccountPicture } from 'assets/images/vector/business-bank-account.svg';
import { ReactComponent as CitizenshipPicture } from 'assets/images/vector/citizenship.svg';
import { ReactComponent as RegistrationPicture } from 'assets/images/vector/registration.svg';
import { ReactComponent as LoansPicture } from 'assets/images/vector/loans.svg';

// Questions
import BA1 from './FaqContent/bankAccounts/BA1.mdx'
import BA2 from './FaqContent/bankAccounts/BA2.mdx'
import BA3 from './FaqContent/bankAccounts/BA3.mdx'
import BA4 from './FaqContent/bankAccounts/BA4.mdx'
import BA5 from './FaqContent/bankAccounts/BA5.mdx'
import BA6 from './FaqContent/bankAccounts/BA6.mdx'
import BA7 from './FaqContent/bankAccounts/BA7.mdx'
import BA8 from './FaqContent/bankAccounts/BA8.mdx'
import BA9 from './FaqContent/bankAccounts/BA9.mdx'

import SC1 from './FaqContent/secondCitizenship/SC1.mdx'
import SC2 from './FaqContent/secondCitizenship/SC2.mdx'
import SC3 from './FaqContent/secondCitizenship/SC3.mdx'
import SC4 from './FaqContent/secondCitizenship/SC4.mdx'
import SC5 from './FaqContent/secondCitizenship/SC5.mdx'
import SC6 from './FaqContent/secondCitizenship/SC6.mdx'

import T1 from './FaqContent/taxes/T1.mdx'
import T2 from './FaqContent/taxes/T2.mdx'
import T3 from './FaqContent/taxes/T3.mdx'
import T4 from './FaqContent/taxes/T4.mdx'
import T5 from './FaqContent/taxes/T5.mdx'
import T6 from './FaqContent/taxes/T6.mdx'
import T7 from './FaqContent/taxes/T7.mdx'
import T8 from './FaqContent/taxes/T8.mdx'
import T9 from './FaqContent/taxes/T9.mdx'
import T10 from './FaqContent/taxes/T10.mdx'

import OC1 from './FaqContent/offshoreCompany/OC1.mdx'
import OC2 from './FaqContent/offshoreCompany/OC2.mdx'
import OC3 from './FaqContent/offshoreCompany/OC3.mdx'
import OC4 from './FaqContent/offshoreCompany/OC4.mdx'

import RS1 from './FaqContent/residencySolutions/RS1.mdx'
import RS2 from './FaqContent/residencySolutions/RS2.mdx'
import RS3 from './FaqContent/residencySolutions/RS3.mdx'
import RS4 from './FaqContent/residencySolutions/RS4.mdx'
import RS5 from './FaqContent/residencySolutions/RS5.mdx'

const FaqData =
    [
        {
            title: 'Bank Accounts',
            image: <PersonalBankAccountPicture />,
            questions:
                [
                    {
                        title: 'What do I need for opening an offshore bank account?',
                        mdxComponent: BA1,
                        metaData: {
                            description: "For personal accounts, offshore banks require personal information, such as your full name, citizenship, personal identification number, date of birth, address, and CV."
                        }
                    },
                    {
                        title: 'What is an offshore account?',
                        mdxComponent: BA2,
                        metaData: {
                            description: "An offshore (or overseas) bank account is an account held in a country you don't reside in. It is very similar to any everyday banking institution."
                        }
                    },
                    {
                        title: 'Can I open an offshore bank account remotely?',
                        mdxComponent: BA3,
                        metaData: {
                            description: "Yes, you can open an offshore bank account remotely. However, if you are considering opening the account remotely, your options will be significantly more limited than opening in person."
                        }
                    },
                    {
                        title: 'Can a non-resident open a bank account?',
                        mdxComponent: BA4,
                        metaData: {
                            description: "If you would like to open a bank account in a country that you have no ties with, you need to know the requirements of opening an account, as well as other ins and outs to save your time and money."
                        }
                    },
                    {
                        title: 'What is the best country to open a bank account in?',
                        mdxComponent: BA5,
                        metaData: {
                            description: "When it comes to the best countries for offshore banking, people may generate different ideas. For some, political stability is the most important, while for others – asset protection laws."
                        }
                    },
                    {
                        title: 'What is EMI account?',
                        mdxComponent: BA6,
                        metaData: {
                            description: "By definition, EMI account is an account held in an Electronic Money Institution, in other words – a digital bank. It is a fintech institution similar to an offshore bank."
                        }
                    },
                    {
                        title: 'Is it legal to have offshore bank accounts?',
                        mdxComponent: BA7,
                        metaData: {
                            description: "There's nothing illegal about establishing an offshore account unless you do it with the intent of tax evasion. In fact, many international executive professionals and expats open offshore accounts when they relocate abroad."
                        }
                    },
                    {
                        title: 'What is the benefit of offshore banking?',
                        mdxComponent: BA8,
                        metaData: {
                            description: "Offshore banking provides many benefits. First and foremost, you will keep your money in a secure and central location, connected to your local accounts."
                        }
                    },
                    {
                        title: 'Are offshore accounts secure?',
                        mdxComponent: BA9,
                        metaData: {
                            description: "If you open an offshore account in an economically stable country, offshore banking will prove to be a secure way of managing your finances."
                        }
                    }
                ]
        },
        {
            title: 'Second Citizenship',
            image: <CitizenshipPicture />,
            questions:
                [
                    {
                        title: 'How to get second citizenship?',
                        mdxComponent: SC1,
                        metaData: {
                            description: "There are several ways to get a second citizenship and the corresponding passport, including citizenship by ancestry, citizenship by residence, and citizenship by investment."
                        }
                    },
                    {
                        title: 'Can a US citizen have dual citizenship?',
                        mdxComponent: SC2,
                        metaData: {
                            description: "Yes, the United States allow dual citizenship. It is possible to hold onto your American passport and still acquire a second citizenship in the country of your choice."
                        }
                    },
                    {
                        title: 'What are the advantages of dual citizenship?',
                        mdxComponent: SC3,
                        metaData: {
                            description: "Dual citizens are allowed to carry both country passports. For instance, being a US citizen and a citizen of France, you can travel more easily between them."
                        }
                    },
                    {
                        title: 'What is the easiest country to get dual citizenship?',
                        mdxComponent: SC4,
                        metaData: {
                            description: "By any means, Vanuatu's second passport is the easiest and most advantageous dual citizenship to get. The timeline for the whole process can take up to 2 months."
                        }
                    },
                    {
                        title: 'Does dual citizenship allow two passports?',
                        mdxComponent: SC5,
                        metaData: {
                            description: "People who hold the citizenship of two countries at once are called dual citizens."
                        }
                    },
                    {
                        title: 'In what countries can I get a second passport?',
                        mdxComponent: SC6,
                        metaData: {
                            description: "If you are lucky enough to have ancestors from places like Lithuania, Spain or Germany, then obtaining a second passport is a very straightforward process, assuming that you can document your family ancestry."
                        }
                    }
                ]
        },
        {
            title: 'Taxes',
            image: <RegistrationPicture />,
            questions:
                [
                    {
                        title: 'What is a tax haven?',
                        mdxComponent: T1,
                        metaData: {
                            description: "Generally, a tax haven is an offshore country that holds foreign business or individuals liable to little or no taxes in an economically and politically stable environment."
                        }
                    },
                    {
                        title: 'Do you pay taxes on offshore bank accounts?',
                        mdxComponent: T2,
                        metaData: {
                            description: "Offshore bank accounts must be declared to the holder's home country for tax reasons. However, some countries allow foreigners to earn capital gains tax-free."
                        }
                    },
                    {
                        title: 'Which country is tax-free?',
                        mdxComponent: T3,
                        metaData: {
                            description: "There are many countries without taxes, so-called true tax-havens. However, tax laws around the world have changed drastically for many westernized countries as an attempt to prevent assets from leaving the country."
                        }
                    },
                    {
                        title: 'What is offshore income?',
                        mdxComponent: T4,
                        metaData: {
                            description: "Income is considered offshore income if it comes from a territory outside your tax residency country."
                        }
                    },
                    {
                        title: 'Do you have to pay taxes on international income?',
                        mdxComponent: T5,
                        metaData: {
                            description: "Taxes on international income depend on the country of your tax residency. For example, US citizens and resident aliens earning a certain amount of income from foreign sources may have to pay income taxes on foreign income."
                        }
                    },
                    {
                        title: 'Can you be taxed in two countries?',
                        mdxComponent: T6,
                        metaData: {
                            description: "Two countries might consider you a tax resident at the same time in some cases. Thus, you could be asked to pay taxes on your total income in both nations."
                        }
                    },
                    {
                        title: 'What is a Controlled Foreign Corporation (CFC)?',
                        mdxComponent: T7,
                        metaData: {
                            description: "A corporate entity is considered a controlled foreign corporation (CFC) if it is registered or conducts operations in a different country or jurisdiction than the controlling owners' residency."
                        }
                    },
                    {
                        title: 'What is the Common Reporting Standard (CRS)?',
                        mdxComponent: T8,
                        metaData: {
                            description: "The Common Reporting Standard (CRS) asks jurisdictions and countries to obtain information from their financial institutions and exchange that information with other jurisdictions automatically on an annual basis."
                        }
                    },
                    {
                        title: 'What is the Permanent Establishment (PE)?',
                        mdxComponent: T9,
                        metaData: {
                            description: "Permanent establishment (PE) can be regarded as a tax concept that is often included in trade agreements and varies from country to country."
                        }
                    },
                    {
                        title: 'What are non-CRS countries?',
                        mdxComponent: T10,
                        metaData: {
                            description: "The list of Common Reporting Standard (CRS) members is always changing. Year after year, more countries join this agreement."
                        }
                    },
                ]
        },
        {
            title: 'Offshore Company',
            image: <BusinessBankAccountPicture />,
            questions:
                [
                    {
                        title: 'What is an offshore company?',
                        mdxComponent: OC1,
                        metaData: {
                            description: "An offshore company is incorporated or registered in a jurisdiction that is different from where its shareholders reside."
                        }
                    },
                    {
                        title: 'What is the best country to set up an offshore company?',
                        mdxComponent: OC2,
                        metaData: {
                            description: "There are many things to take into consideration while deciding the best jurisdiction for your offshore company. Offshore jurisdictions worldwide share a number of characteristics, such as fast incorporation procedure and no taxes for companies."
                        }
                    },
                    {
                        title: 'What are the benefits of an offshore company?',
                        mdxComponent: OC3,
                        metaData: {
                            description: "The benefits of a proper offshore company are numerous. It can offer advantages to an existing business or an individual on many levels: offshore asset protection, confidentiality, enhanced privacy, and tax savings."
                        }
                    },
                    {
                        title: 'How to get a crypto exchange license?',
                        mdxComponent: OC4,
                        metaData: {
                            description: "Many different jurisdictions offer a cryptocurrency exchange license. To illustrate the cryptocurrency company formation process, we will choose Estonia as an example."
                        }
                    },
                ]
        },
        {
            title: 'Residency Solutions',
            image: <LoansPicture />,
            questions:
                [
                    {
                        title: 'Can I be a tax resident in 2 countries?',
                        mdxComponent: RS1,
                        metaData: {
                            description: "Two countries might consider you a tax resident at the same time in some cases. Thus, you could be asked to pay taxes on your total income in both nations."
                        }
                    },
                    {
                        title: 'Can I have no tax residency?',
                        mdxComponent: RS2,
                        metaData: {
                            description: "Yes, there are plenty of countries with zero or very low taxes, and you can actually move there to save on your tax bill."
                        }
                    },
                    {
                        title: 'What does it mean to be a tax resident?',
                        mdxComponent: RS3,
                        metaData: {
                            description: "Generally, individuals are considered residents under a tax treaty and are subject to taxation in the country where they maintain their primary place of abode."
                        }
                    },
                    {
                        title: 'Can I live in Country A and pay taxes in Country B?',
                        mdxComponent: RS4,
                        metaData: {
                            description: "If you are not considered a resident in Country A, you will not have to pay taxes on your foreign income. If you are residing in Country A, you will typically pay taxes on your foreign income."
                        }
                    },
                    {
                        title: 'How is tax residency determined?',
                        mdxComponent: RS5,
                        metaData: {
                            description: "Tax residency is determined under the domestic tax laws of each jurisdiction. There might be situations when a person qualifies as a tax resident of more than one jurisdiction."
                        }
                    },
                ]
        }
    ]

export default FaqData