
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Can a US citizen have dual citizenship?`}</h1>
    <p>{`Yes, the United States allow dual citizenship. It is possible to hold onto your American passport and still acquire a second citizenship in the country of your choice.`}</p>
    <p>{`If you have decided to acquire dual citizenship and live overseas already, you will not lose your American citizenship. Your residency status abroad, including your application for second citizenship in another country, has no impact on your current one.`}</p>
    <p>{`It is essential to recognize that a 'master nationality' rule is applied in the US. It recognizes an individual's US nationality only, regardless of whether that individual holds any other citizenship.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;