
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import authors from 'components/blog/BlogAuthorData';
export const metadata = {
  author: authors.gediminas,
  headline: "How Does CRS Affect Your Taxes?",
  date: "2021 01 18",
  slug: "how-does-crs-affect-your-taxes",
  description: "The implementation of CRS has made offshore banking and taxation increasingly complex. Let's look into the history of CRS to develop an understanding of the legislation and how it affects your taxes.",
  imageAlt: 'cluttered desk with papers and books'
};

const layoutProps = {
  metadata
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In the past decade, the nature of offshore banking has evolved significantly. In particular, because of a relatively new system called the Common Reporting Standard (CRS). `}</p>
    <p>{`This standard has dramatically shifted the nature of offshore banking and international taxes. Furthermore, the implementation of CRS has made offshore banking and taxation increasingly complex.`}</p>
    <p>{`Let's look into the history of CRS to develop an understanding of the legislation and how it affects you.`}</p>
    <h2>{`The History and Current Effects of the Common Reporting Standard`}</h2>
    <p>{`Following several massive international scandals, such as the 2014 Luxembourg Leaks, world governments took decisive action against tax evasion and base erosion.`}</p>
    <p>{`In 2014, the `}<a parentName="p" {...{
        "href": "https://www.oecd.org/about/"
      }}>{`Organisation for Economic Co-operation and Development`}</a>{` (OECD) approved new legislation that dramatically altered the offshore banking landscape.`}</p>
    <p>{`This legislation was CRS.`}</p>
    <p>{`CRS created and implemented new global standards that require governments to obtain information from their domestic financial institutions and share that information with other jurisdictions. `}</p>
    <p>{`Essentially, if you are a tax resident, citizen, or banking in a CRS jurisdiction, that government can exchange your information with your home nation or tax residency, which can lead to them taxing you as if you lived domestically.`}</p>
    <p>{`In short, CRS allows nations to monitor and potentially tax the financial activities of citizens abroad. `}</p>
    <h2>{`What Information Does CRS Allow Nations to Exchange?`}</h2>
    <p>{`According to the OECD, on an annual basis, nations in the agreement will automatically exchange the following information:`}</p>
    <ul>
      <li parentName="ul">{`Name, address, and Taxpayer Identification Number`}</li>
      <li parentName="ul">{`Date and place of birth`}</li>
      <li parentName="ul">{`Bank account number`}</li>
      <li parentName="ul">{`The account balance at the end of the proper calendar year, or, if the account was closed, report of its closure`}</li>
      <li parentName="ul">{`Information on the relevant financial institution`}</li>
      <li parentName="ul">{`Relevant information on capital gains in that year, depending on the type of account`}</li>
    </ul>
    <p>{`The exchange of this information allows nations to keep track of their citizens and finances overseas.`}</p>
    <p>{`This means that it is not enough to simply open an offshore bank account while continuing to live in a high-tax jurisdiction.`}</p>
    <p><img parentName="p" {...{
        "src": "blog17-1.jpg",
        "alt": "stacks of paper"
      }}></img></p>
    <h2>{`Who Does CRS Apply To?`}</h2>
    <p>{`With over 100 nations in the agreement, CRS is a powerful blockade that affects nations around the entire globe. Countries such as Denmark, the United Kingdom, France, Mexico, Spain, Luxembourg, and more are all members.`}</p>
    <p>{`And, the list of `}<a parentName="p" {...{
        "href": "https://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/crs-by-jurisdiction/crs-by-jurisdiction-2018.htm"
      }}>{`member countries`}</a>{` continues to grow. In 2018, over 35 new nations agreed to the reporting standards, including popular banking jurisdictions such as Switzerland and the United Arab Emirates. `}</p>
    <p>{`Finally, this global exchange of information is expected to increase. Complete tax and banking privacy is no longer a reality. `}</p>
    <p>{`So, you may be wondering...`}</p>
    <h2>{`How Do I Legally Optimize My Taxes?`}</h2>
    <p>{`Like most tax legislation, the answer is complicated and varies depending on the case. `}</p>
    <p>{`But first, it must be emphasized that tax evasion is illegal and not worth the risk. You will get caught and face fines and potentially jail time.`}</p>
    <p>{`For this reason, it is vital that you consult a professional when planning your international tax strategy so that you do not accidentally break any local or international laws.`}</p>
    <p>{`Now, it is worth noting that even with legislation like CRS, there are still `}<a parentName="p" {...{
        "href": "/blog/top-5-benefits-of-offshore-accounts"
      }}>{`major benefits to offshore banking`}</a>{` and incorporation.`}</p>
    <p>{`But to determine your ideal tax strategy, you need to assess the laws of the countries where you hold citizenship, have `}<a parentName="p" {...{
        "href": "/blog/how-to-pay-lower-taxes"
      }}>{`tax residency`}</a>{`, bank, and do business,`}</p>
    <p>{`Depending on what countries you have tax obligations in, you may be required to report your international earnings as that jurisdiction requires. However, whether you will owe taxes on those international earnings depends on the laws of that country.`}</p>
    <p>{`In fact, many countries require you to report your foreign earnings even if they are not a part of the CRS. `}</p>
    <p>{`For example, the United States is not adherent to CRS. But they maintain their own policy for tracking financial assets owned by U.S citizens abroad called FACTA (the Fair and Accurate Credit Transactions Act).`}</p>
    <p>{`This applies to many other countries too. These countries may require their citizens to report offshore earnings or assets, regardless of CRS. `}</p>
    <p>{`Whether a country will require you to pay tax on your income or assets overseas depends on their tax laws. `}</p>
    <h2>{`Territorial Taxation and Tax Residency`}</h2>
    <p>{`While establishing tax residency in a country with low taxes or territorial taxation may not eliminate your reporting obligations, it can help lower your tax burden. `}</p>
    <p>{`Under a territorial taxation system, only income earned within a jurisdiction's borders is tax-free. Income earned outside of the country is free from taxation `}</p>
    <p><img parentName="p" {...{
        "src": "blog17-2.jpg",
        "alt": "map of near east"
      }}></img></p>
    <p>{`Simply put, becoming a tax resident of a low-tax or territorial taxation country that is friendly towards foreigners can be a vital part of your tax optimization strategy. `}</p>
    <p>{`Countries such as the UAE, Panama, and Cyprus can be good options for individuals looking to lower their tax burden and `}<a parentName="p" {...{
        "href": "/blog/top-5-best-countries-to-open-an-offshore-bank-account/"
      }}>{`bank offshore`}</a>{`.`}</p>
    <p>{`Nations such as these are useful regardless of CRS because they generally have low- or no-income tax, a level of banking privacy, or offer additional tax benefits to foreign individuals.`}</p>
    <p>{`Next, another crucial aspect to consider is how your country of citizenship taxes income and assets abroad. `}</p>
    <p>{`If your country of citizenship is a low-tax or territorial tax jurisdiction, this may be relatively simple. However, if your country of citizenship levies taxes on international income and assets, then the situation becomes more complicated, and more extensive tax planning will be needed. `}</p>
    <h2>{`Developing Your Tax Efficiency and Banking Strategy`}</h2>
    <p>{`Even with CRS, tax optimization is possible. A new tax residency and an offshore bank account in the right country is often an essential part of a strong tax optimization plan.`}</p>
    <p>{`Again, exact regulations and measures vary from nation to nation, so it is vital to consult with a professional when making important tax decisions.`}</p>
    <p>{`Due to the complex nature and broad scope of CRS legislation, changing tax residencies, and offshore banking, we encourage you to contact us `}<a parentName="p" {...{
        "href": "/contact"
      }}>{`here`}</a>{` to begin consultation with us to help you achieve your tax efficiency goals.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;