import React from 'react'
import Helmet from 'react-helmet'

function LiveChatScript(){
    return(
        <Helmet
            script={[
                {
                    type: 'text/javascript',
                    innerHTML: `
                        window.__lc = window.__lc || {}; window.__lc.license = 11794131; (function() { var lc = document.createElement('script'); lc.type = 'text/javascript'; lc.defer = true; lc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(lc, s); })();
                    `
                }
            ]}
            noscript={[
                {
                    type: 'text/javascript',
                    innerHTML: `
                        <a href="https://www.livechatinc.com/chat-with/11794131/" rel="nofollow">Chat with us</a>, powered by <a href="https://www.livechatinc.com/?welcome" rel="noopener nofollow" target="_blank">LiveChat</a>
                    `
                }
            ]}
        >
        </Helmet>
    )
}

export default LiveChatScript