import React from 'react';
import style from './serviceComponent.module.scss'
import InfoTemplate from 'components/services/serviceTemplate/InfoTemplate'
import ServiceTemplate from 'components/services/serviceTemplate/ServiceTemplate'

function PremiumCards({data}) {
	const headerRightContent=
	<div>
		<p className={style.paragraph}>
			Whether you’re on a move or office-bound, the premium and super-premium cards will benefit you and your business. Designed for those who are looking for exceptional service, the premium and super-premium cards give you unlimited spending power, as well as access to a variety of exclusive events.
		</p>
	</div>

	return <ServiceTemplate data={data} headerRightContent={headerRightContent} leftContent={<LeftContent />} rightContent={<RightContent />}/>
}

function LeftContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>Say YES to the impossible</h2>
			<p className={style.paragraph}>
				Super-premium and premium payment cards come coupled with unique benefits. Consider it your personal assistant ready to help you with scheduling a business trip itinerary, sourcing a present for a friend, or simply setting up your social schedule. On top of that, the super-premium and premium cards grant you access to over 1000 lounges serving pre-boarding snacks, refreshments, and amenities.
			</p>
		</div>
	)
}

function RightContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>Some of the perks of the offshore credit and debit cards:</h2>
			<ul className={style.list}>
				<li>- Spending limits of up to 75,000 EUR per day</li>
				<li>- Cash withdrawal limits of up to 50,000 EUR per month</li>
				<li>- Best value offers at the world’s top hotels</li>
				<li>- Private jets chartered at supplier rates</li>
				<li>- Airport fast-track & priority access</li>
				<li>- VIP airport assistance</li>
				<li>- Corporate events & VIP entertaining</li>
			</ul>

			<InfoTemplate content={
				<div>Annual fees start at 500 EUR <br />The price for premium card starts at 1000 EUR</div>
			}/>
		</div>
	)
}

export default PremiumCards;
