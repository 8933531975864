import React from 'react';
import style from './socialMediaLinks.module.scss';
import { ReactComponent as LinkedIn } from 'assets/images/vector/link.svg';
import { ReactComponent as LinkedInWhite } from 'assets/images/vector/linked-in-white.svg'

function SocialMediaLinks({ white }) {
	return (
		<div className={style.container}>
			<a href='https://www.linkedin.com/company/offshorebankaccounts/'
				target='_blank'
				rel="noopener noreferrer"
			>
				{white ? <LinkedInWhite /> : <LinkedIn />}
			</a>
		</div>
	);
}
export default SocialMediaLinks;
