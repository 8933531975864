import React from 'react'
import style from './faqRouteTree.module.scss'
import { Link } from 'react-router-dom'

import { ReactComponent as Arrow } from 'assets/images/vector/arrow-right.svg'

function FaqRouteTree({ routeList }) {
    return (
        <div>
            <Link to="/faq" className={style.link}>FAQ</Link>
            {
                routeList.map((route, key) => {
                    return (
                        key === routeList.length - 1 ?
                            <div key={key} className={style.routeBlock}>
                                <Arrow className={style.arrow} />
                                <div className={style.link}>{route.name}</div>
                            </div>
                            :
                            <Link to={route.path} key={key} className={style.routeBlock}>
                                <Arrow className={style.arrow} />
                                <div className={style.link}>{route.name}</div>
                            </Link>
                    )
                }
                )
            }
        </div>
    )
}

export default FaqRouteTree