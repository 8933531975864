import React, { createElement } from 'react';

import { Route, Switch } from 'react-router-dom';
import slugify from 'helpers/Slugify';

import FaqData from 'components/faq/FaqData'
import BlogAuthorData from 'components/blog/BlogAuthorData'
import ServiceData from 'components/services/ServiceData'

import Home from 'pages/home/Home';
import BlogMenu from 'pages/blog/BlogMenu';
import Contact from 'pages/contact/Contact';
import PrivacyPolicy from 'pages/legal/PrivacyPolicy';
import CookiePolicy from 'pages/legal/CookiePolicy';
import FaqMenu from 'pages/faq/FaqMenu';
import FaqInsideCategory from 'components/faq/FaqInsideCategory';
import FaqInsideQuestion from 'components/faq/FaqInsideQuestion';
import BlogEntry from 'components/blog/BlogEntry';
import getBlogContents from 'helpers/blogLoader';

import BlogAuthor from 'pages/blog/BlogAuthor';

function Routes() {
	const blogContents = getBlogContents();

	return (
		<Switch>
			<Route exact path="/" component={Home} />
			<Route exact path="/blog" component={BlogMenu} />
			<Route path="/contact" component={Contact} />
			<Route path="/privacy" component={PrivacyPolicy} />
			<Route path="/cookies" component={CookiePolicy} />

			<Route exact path="/faq" component={FaqMenu} />
			{/* Automatically generating routes for blog entries */}
			{
				blogContents.map((c, k) =>
					<Route path={"/blog/" + c.meta.slug} key={k}>
						<BlogEntry BlogContent={c.data} metadata={c.meta} id={k} />
					</Route>
				)
			}

			{/* Automatically generating routes for blog authors */}
			{
				Object.values(BlogAuthorData).map((author, k) =>
					<Route path={"/author/" + author.routePath} key={k}>
						<BlogAuthor authorData={author} />
					</Route>
				)
			}

			{/* Automatically generating routes for faq inside category*/}
			{
				FaqData.map((category, key) => {
					return (
						<Route exact path={'/faq/' + slugify(category.title)} key={key}>
							<FaqInsideCategory category={category}
								routeList={[
									{
										name: category.title,
										path: '/faq/' + slugify(category.title)
									}
								]} />
						</Route>
					)
				})
			}

			{/* Automatically generating routes for faq inside category inside question*/}
			{
				FaqData.map(category => {
					return (
						category.questions.map((question, key) => {
							return (
								<Route exact path={'/faq/' + slugify(category.title) + '/' + slugify(question.title)} key={key}>
									<FaqInsideQuestion category={category} question={question} routeList={[
										{
											name: category.title,
											path: '/faq/' + slugify(category.title)
										},
										{
											name: question.title,
											path: '/faq/' + slugify(category.title) + '/' + slugify(question.title)
										}
									]} />
								</Route>
							)
						})
					)
				})
			}

			{/* Automatically generating routes for services */}
			{
				ServiceData.map((data, key) =>
					<Route exact path={data.link} key={key}>
						{createElement(data.component, { data: data })}
					</Route>
				)
			}

			{/* Automatically generating routes for sub services */}
			{
				ServiceData.map(data =>
					data.subServices?.map((subData, key) =>
						<Route exact path={subData.link} key={key}>
							{createElement(subData.component, { data: data, subData: subData })}
						</Route>
					))
			}
		</Switch>
	)
}

export default Routes
