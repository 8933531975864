import React from 'react'
import style from './contactMeans.module.scss'

import { ReactComponent as Envelope } from 'assets/images/vector/mail.svg';
import { ReactComponent as Whatsapp } from 'assets/images/vector/whatsapp.svg';
import { ReactComponent as Skype } from 'assets/images/vector/skype.svg';
import { ReactComponent as Telegram } from 'assets/images/vector/telegram.svg';
import { ReactComponent as Signal } from 'assets/images/vector/signal.svg';

function ContactMeans() {
    return (
        <div>
            <div className={style.link}>
                <Envelope className={style.icon} />
                <a href="mailto:hello@offshorebankaccounts.com"><p className={style.text}>hello@offshorebankaccounts.com</p></a>
            </div>
            <div className={style.link}>
                <Skype className={style.icon} />
                <a href="skype:live:gediminas_71?chat"><p className={style.text}>live:gediminas_71</p></a>
            </div>
            <div className={style.link}>
                <Whatsapp className={style.icon} />
                <p className={style.number}>+971562077140</p>
            </div>
            <div className={style.link}>
                <Telegram className={style.icon} />
                <p className={style.number}>+971562077140</p>
            </div>
            <div className={style.link}>
                <Signal className={style.icon} />
                <p className={style.number}>+971562077140</p>
            </div>
        </div>
    )
}

export default ContactMeans