
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`What do I need for opening an offshore bank account?`}</h1>
    <p>{`For personal accounts, offshore banks require personal information, such as your full name, citizenship, personal identification number, date of birth, address, and CV. They will also ask to provide a copy of your documents, such as your passport and utility bill.`}</p>
    <p>{`Suppose you would like to open an offshore bank account for your business. In that case, you will also need to provide your business information, such as company name, address, registration number, date of registration, and company website. Certificate of Incorporation, Memorandum & Articles of Association, Register of Shareholders, Certificate of Good Standing or Certificate of Incumbency, will also be required.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;