
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Can I open an offshore bank account remotely?`}</h1>
    <p>{`Yes, you can open an offshore bank account remotely. However, if you are considering opening the account remotely, your options will be significantly more limited than opening in person. Banks are increasingly requiring in-person meetings to open accounts and non-residents are viewed as higher-risk clients.`}</p>
    <p>{`If you want to start your offshore bank account opening remotely as a non-resident, please `}<a parentName="p" {...{
        "href": "https://offshorebankaccounts.com/media/pre-approval-business.pdf"
      }}>{`download our pre-approval form`}</a>{` for a business account. After filling in the pre-approval form, send it to our team at `}<a parentName="p" {...{
        "href": "mailto:hello@offshorebankaccounts.com."
      }}>{`hello@offshorebankaccounts.com.`}</a>{` We will get back to you with potential options for your account.`}</p>
    <p>{`If you want to open a personal account, send us an email with your full name, countries of citizenship and tax residency, and information about the source of your wealth.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;