
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import authors from 'components/blog/BlogAuthorData';
import BlogImage from 'components/blog/contentComponents/BlogImage';
export const metadata = {
  author: authors.gediminas,
  headline: "TransferWise Closed Your Account?",
  date: "2021 02 18",
  slug: "transferwise-closed-your-account",
  description: "If you buy and sell cryptocurrency, deal in a high-risk industry, or have had your TransferWise account closed, you might want to seriously reconsider dealing with TransferWise moving forward.",
  imageAlt: 'bad good paper notes'
};

const layoutProps = {
  metadata
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`If you buy and sell cryptocurrency, deal in a high-risk industry, or have had your TransferWise account closed, you might want to seriously reconsider dealing with TransferWise moving forward.`}</p>
    <p>{`The London based company, TransferWise, has become a popular method for transferring funds across borders. However, TransferWise also has some serious drawbacks.`}</p>
    <p>{`TransferWise often deactivates accounts with no warning to the customer. Additionally, they sometimes even hold onto customers’ funds for months.`}</p>
    <p>{`In that situation, you may not have access to your money when you need it. `}</p>
    <p>{`Let’s explore some of the reasons why TransferWise may deactivate your account.`}</p>
    <p><img parentName="p" {...{
        "src": "blog20-1.jpg",
        "alt": "time to say goodbye green sign"
      }}></img></p>
    <h2>{`The Problem With TransferWise`}</h2>
    <p>{`The main benefit of TransferWise is the ability to process cross-border transactions quickly with relatively low fees. `}</p>
    <p>{`Despite these benefits, the risk involved may not be worth the possible months of headache and economic loss that could occur if your account gets deactivated.`}</p>
    <p>{`We have to weigh the risks and benefits of utilizing any financial services. `}</p>
    <p>{`First off, TransferWise Customer Service is not allowed to view the reason behind why your account was deactivated, which can make getting answers difficult and time-consuming.`}</p>
    <p>{`Already, this is a problem if you appreciate transparency when handling your finances.`}</p>
    <p>{`Now, onto a few reasons why they might have closed your account.`}</p>
    <p>{`According to TransferWise’s `}<a parentName="p" {...{
        "href": "https://transferwise.com/help/articles/2961844/my-account-was-deactivated"
      }}>{`help page`}</a>{`, they may deactivate accounts if:`}</p>
    <ul>
      <li parentName="ul">{`The account appears to be a duplicate of another account`}</li>
      <li parentName="ul">{`Its security has been compromised`}</li>
      <li parentName="ul">{`There are transactions TransferWise cannot complete`}</li>
    </ul>
    <p>{`The last reason appears a little vague, and that is because it is. `}</p>
    <p>{`It could be because you buy and sell cryptocurrency, which goes against TransferWise’s terms and conditions. `}</p>
    <p>{`Or it could be because you work in a high-risk industry such as gambling. `}</p>
    <p>{`High-risk might sound like an extreme category, but many of the industries are quite commonplace. For example, legal consultation and travel services are both considered high-risk.`}</p>
    <h2>{`What Industries Are Not Allowed To Use TransferWise?`}</h2>
    <p>{`Obviously, TransferWise does not allow for any illegal business to use their platform. If a user is caught breaking local, national, or international law or regulations, they are not allowed to use TransferWise.`}</p>
    <p>{`Additionally, businesses that work with the legal marijuana trade, CBD products, tobacco, pharmaceuticals, and alcohol are not allowed to use TransferWise. Likewise, they do not allow gas, oil, and other companies based in the United Arab Emirates to use their services either.`}</p>
    <p>{`The company has a full list of banned industries in their `}<a parentName="p" {...{
        "href": "https://transferwise.com/acceptable-use-policy-eea"
      }}>{`acceptable use policy`}</a>{`.`}</p>
    <p>{`Interestingly, TransferWise does vary its rules across international borders. `}</p>
    <p>{`TransferWise does not allow trusts and foundations from countries outside of Canada, the European Economic Area and/or the European Union, Switzerland, the USA, Australia, or New Zealand to use the platform. `}</p>
    <p>{`In addition to trusts and foundations, nonprofit organizations and charities are only allowed to use TransferWise if they are from the jurisdictions mentioned above. `}</p>
    <p>{`Trust and corporate service providers that are involved in the following are also not accepted:`}</p>
    <ul>
      <li parentName="ul">{`Opening accounts on behalf of other businesses`}</li>
      <li parentName="ul">{`Offshore company formation`}</li>
      <li parentName="ul">{`Using nominee directors and/or shareholders to obscure company owners`}</li>
    </ul>
    <p>{`It’s also vital to note that individuals or businesses exchanging cryptocurrencies or other virtual currencies are prohibited from using TransferWise. `}</p>
    <p>{`This includes payments that are for the purpose of purchasing cryptocurrencies. `}</p>
    <p>{`If your business operates in any of these industries, we highly recommend that you open a legitimate business bank account with the help of a professional consultant.`}</p>
    <h2>{`Banking Solutions For Cryptocurrency Owners`}</h2>
    <p>{`As a cryptocurrency owner, you need to find a crypto-friendly financial institution. But options may feel limited as more traditional banks are usually not crypto-friendly. `}</p>
    <BlogImage src={"blog20-2.jpg"} alt="gold bitcoin balancing" style={{
      objectPosition: "5%"
    }} mdxType="BlogImage" />
    <p>{`And, as previously mentioned, TransferWise explicitly bans those who are exchanging cryptocurrency from using their services as well.`}</p>
    <p>{`So, what are your alternatives if you buy and sell cryptocurrencies? `}</p>
    <p>{`Unfortunately, there are no easy answers. The right banking solution will depend on a variety of factors. However, an offshore bank account or fintech can often be a good solution.`}</p>
    <p>{`Still, every bank has its `}<a parentName="p" {...{
        "href": "/blog/why-you-get-rejected-by-banks"
      }}>{`own requirements`}</a>{`, rules, prohibited users, and benefits. Some banks require an in-person visit to open an account, and others do not. Most banks require an initial deposit, but the amounts will vary. Processing times also differ between institutions. `}</p>
    <p>{`Fintech offers advantages such as ease of use and low fees. However, it can take time and effort to find a fintech service that is willing to work with crypto owners. `}</p>
    <p>{`Because finding a bank as a cryptocurrency owner can be complex and time consuming, using an agent or introducer is usually the most effective solution. `}</p>
    <p>{`An agent who is familiar with a variety of banks can help you find the best fit for you and walk you through each step of the process. At Offshore Bank Accounts, that’s exactly what we do. We make finding the right crypto-friendly bank straightforward and stress-free.`}</p>
    <h2>{`Personal Accounts vs. Business Accounts: Which Is Best For You?`}</h2>
    <p>{`Using a personal account from TransferWise, when you actually need a business account can also be an issue. Retail banking, also known as consumer or personal banking, is the sector that provides financial services to the general public. Allowing consumers to manage their funds, retail banks offer services such as checking accounts, savings accounts, mortgages, and many other services. `}</p>
    <p>{`These banks are orientated to individuals or families that simply manage their finances.`}</p>
    <p>{`On the other hand, business banking, or corporate banking, provides services to corporations and businesses.`}</p>
    <p>{`The key difference is that a business bank account will cater to the business and business owner rather than consumer-orientated like a retail bank.`}</p>
    <p>{`Also, a bank specializing in business banking will generally have a smaller clientele base than a retail bank, which means more personalized service. `}</p>
    <p>{`Overall, if you’re a business owner, it is vital that you have a business account so that you can manage your corporate finances efficiently, legally, and smoothly with the help of a business-oriented bank.`}</p>
    <h2>{`What Should You Do Now?`}</h2>
    <p>{`If TransferWise closed your account without warning and is holding onto your money, you should email Customer Service and make sure that your money will be returned to you. `}</p>
    <p>{`Next, we recommend that you begin searching for alternatives to TransferWise so that you can avoid these headaches in the future.`}</p>
    <p>{`If you deal with cryptocurrencies or are in a high-risk industry, you should consider opening an account at a bank that will cater more specifically to your needs while keeping your money safe.`}</p>
    <p>{`If you would like to get started today, we offer these services. You can start by contacting us `}<a parentName="p" {...{
        "href": "/contact"
      }}>{`here`}</a>{`.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;