
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Do you pay taxes on offshore bank accounts?`}</h1>
    <p>{`Offshore bank accounts must be declared to the holder's home country for tax reasons. However, some countries allow foreigners to earn capital gains tax-free. `}</p>
    <p>{`There are ways to create a tax-free offshore bank account, such as setting up an offshore trust that holds offshore life insurance policy or building an offshore business that earns foreign-sourced active income.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;