
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Is it legal to have offshore bank accounts?`}</h1>
    <p>{`There's nothing illegal about establishing an offshore account unless you do it with the intent of tax evasion. In fact, many international executive professionals and expats open offshore accounts when they relocate abroad. Such accounts provide flexible access to funds anywhere in the world.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;