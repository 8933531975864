import React from 'react'
import style from './letsTalk.module.scss'
import { Link } from 'react-router-dom'

import HeaderTemplate from 'components/templates/HeaderTemplate'
import { ReactComponent as Envelope } from 'assets/images/vector/mail.svg'

function LetsTalk() {
	const leftSideContent =
		<div>
			<div className={style.header}>
				Let's Talk
			</div>

			<div className={style.link}>
				<Envelope className={style.icon} />
				<a href="mailto:hello@offshorebankaccounts.com">hello@offshorebankaccounts.com</a>
			</div>
		</div>

	const rightSideContent =
		<div>
			<div className={style.littleHeader}>
				Tailor-made solutions for your needs
			</div>
			<div className={style.paragraph}>
				We know that it is not easy to navigate in the offshore world, and we do not believe that there exists a one-fits-all solution.
			</div>
			<Link to="/contact"><button className={"simpleButton " + style.button}>Get In Touch</button></Link>
		</div>

	return (
		<div className={style.container}>
			<HeaderTemplate leftSideContent={leftSideContent} rightSideContent={rightSideContent} />
		</div>
	)
}

export default LetsTalk;
