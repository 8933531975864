
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import authors from 'components/blog/BlogAuthorData';
export const metadata = {
  author: authors.gediminas,
  headline: "What Is Controlled Foreign Corporation?",
  date: "2020 12 28",
  slug: "what-is-controlled-foreign-corporation",
  description: "If you’re running an offshore business, you may have heard about controlled foreign corporation and permanent establishment regulations. While these rules may seem complex, they are vital to understanding where you will pay taxes and how much you will pay.",
  imageAlt: 'tax papers and coffee',
  wideImageStyle: {
    objectPosition: "40%"
  }
};

const layoutProps = {
  metadata
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`If you’re running an offshore business, you may have heard about controlled foreign corporation regulations. While these rules may seem complex, they are vital to understanding where you will pay taxes and how much you will pay.`}</p>
    <h2>{`What is a controlled foreign corporation?`}</h2>
    <p>{`Historically, controlled foreign corporation legislation was created to counteract the drawbacks of a simple, territorial tax system.  In the present, a controlled foreign corporation (CFC) is a corporate entity that is registered in and performs business in a different jurisdiction than where the owner or parent company has residency.`}</p>
    <p>{`Along with tax treaties, controlled foreign corporation laws are used by governments to reduce tax evasion, base erosion, and to tax the income a company earns abroad.`}</p>
    <p>{`Base erosion is when a multinational corporation shifts profits from higher-tax jurisdictions to lower-tax jurisdictions, which “erodes” the base taxable income in the higher-tax jurisdiction.`}</p>
    <p>{`By defining a business as a controlled foreign corporation, a country can tax the owners or parent companies on the profits of their foreign or subsidiary company. `}</p>
    <p>{`In other words, CFC regulations allow countries to tax a tax resident or corporation on the profits of their foreign entity.`}</p>
    <p>{`For this reason, controlled foreign corporation legislation can be a major roadblock when creating international tax efficiency.`}</p>
    <p>{`Not all nations have legislation for CFCs. But a few major ones that do enforce these laws include the United States, the United Kingdom, Germany, Japan, Australia, New Zealand, Brazil, Sweden, Iceland, Italy, Russia, and more.`}</p>
    <p>{`Although conceptually similar, each nation has its own CFC regulations with different standards and definitions. This makes consulting an expert necessary when structuring your `}<a parentName="p" {...{
        "href": "/blog/five-reasons-to-start-offshore-company"
      }}>{`offshore company`}</a>{` and `}<a parentName="p" {...{
        "href": "/blog/how-to-pay-lower-taxes"
      }}>{`tax residency`}</a>{`. `}</p>
    <p>{`If you are planning to own an offshore company with the goal of tax reduction, controlled foreign corporation rules could stand in the way if not properly planned for.`}</p>
    <h2>{`How do you know if your corporation is a CFC?`}</h2>
    <p>{`To understand if your company is a CFC, you need to consider the percentage of shareholder ownership, tax rates, and the type of income your company earns. `}</p>
    <p>{`Often, a country will have a shareholder ownership threshold that determines if the company or foreign subsidiary is a CFC. `}</p>
    <p>{`For example, if the offshore company is owned by a high enough percentage of domestic owners, often 50%, the company would be considered a controlled foreign corporation. `}</p>
    <p>{`Once the company is defined as a CFC, the parent company or individual would have to pay the appropriate taxes on the income earned from the company.`}</p>
    <p>{`Another way governments determine whether a company is a CFC is the taxation conditions.`}</p>
    <p>{`If the company would be taxed at a lower rate in the foreign country than it would be in the country where the owner or parent company is based, then CFC rules generally apply, and it would be taxed in the country that you or the parent company is based in. `}</p>
    <p>{`Rules such as these are an effort to cut back on the base erosion mentioned earlier.`}</p>
    <p>{`An additional method that governments use to determine how to tax a foreign entity is by dividing income into the categories of passive and active. `}</p>
    <p>{`Generally, companies that earn the majority of their income from passive income will be taxed as CFCs. Active income accounts for traditional production activities while passive income includes income accrued from interest, rental, dividend income, and more. `}</p>
    <h2>{`CFC blacklists`}</h2>
    <p>{`Some countries also apply CFC rules to any company incorporated in a blacklisted country. The Netherlands and Italy, among others, have created lists of low-tax jurisdictions which they will implement measures against, including the automatic application of CFC rules. `}</p>
    <p>{`This can have serious tax implications for citizens or residents of these countries. `}</p>
    <h2>{`Claiming independent status and whitelists for your corporation`}</h2>
    <p>{`Like all laws, there are exceptions. One common exception for CFC legislation is claiming “independent” status. `}</p>
    <p>{`Countries have different standards for qualifying as independent, but if a company does qualify, it may be exempt from CFC legislation and taxes. `}</p>
    <p>{`Shareholder ownership is a common method for determining independence; minimum and maximum ownership percentages vary from nation to nation.`}</p>
    <p>{`In addition, some countries have whitelists of jurisdictions. If your corporation is incorporated in one of these whitelisted jurisdictions, then your company or foreign subsidiary may not be treated as a CFC. `}</p>
    <p>{`Whitelists for CFC exemptions are common among European countries.`}</p>
    <h2>{`How do controlled foreign corporation regulations affect my taxes?`}</h2>
    <p>{`Without sufficient planning, CFC legislation can fully negate your attempts at tax reduction. `}</p>
    <p>{`For example, if I live in Germany and run a company in the `}<a parentName="p" {...{
        "href": "/blog/company-in-seychelles"
      }}>{`Seychelles`}</a>{` to optimize my income taxes, I may still be taxed by Germany if my personal tax residency is there and my foreign company is defined as a CFC by Germany.`}</p>
    <p>{`If a company is defined as a CFC, then the local CFC legislation of that country will determine what income of the subsidiary will be taxed domestically and at what rate. `}</p>
    <p>{`Furthermore, business owners need to be mindful of the tax implications of their company having permanent establishment in a foreign jurisdiction.`}</p>
    <h2>{`How to legally avoid CFC status`}</h2>
    <p>{`Often, changing tax residencies is beneficial, or necessary, to fully circumvent CFC legislation while staying in compliance. If your tax residency is in a nation that does not enforce CFC rules or that uses territorial taxation, you don’t have to worry about them.`}</p>
    <p>{`In case you’re not sure if your country applies CFC laws to foreign companies, then it’s important to speak to a professional. `}</p>
    <p>{`Due to the complex and specific nature of these codes, personalized advising on how your tax residency and your corporation’s tax residency will interact with CFC laws is essential. Schedule a consultation with us `}<a parentName="p" {...{
        "href": "/contact"
      }}>{`here`}</a>{`. `}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;