import React from 'react'
import style from './pagination.module.scss'

function Pagination({entriesPerPage, totalEntries, currentPage, paginate}){

    const pageNumbers = []

    for (let i = 1; i <= Math.ceil(totalEntries/entriesPerPage); i++){
        pageNumbers.push(i);
    }

    return(
        <div className={style.pagination}>
            {
                pageNumbers.map(number => (
                    <button key={number}
                        className={number === currentPage ? style.active : ""}
                        onClick={() => {
                            paginate(number)
                        }}    
                    >
                        {number}
                    </button>
                ))
            }
        </div>
    )
}

export default Pagination