import React from 'react';
import style from '../serviceComponent.module.scss'
import ServiceTemplate from 'components/services/serviceTemplate/ServiceTemplate'

function Montenegro({data, subData}) {
	const headerRightContent=
	<div>
		<p className={style.paragraph}>
			Montenegro has established itself as one of the fastest-growing economies in the Balkans, attracting numerous investors and individuals seeking to improve their quality of life.
		</p>
	</div>

	return <ServiceTemplate data={data} subData={subData} headerRightContent={headerRightContent} leftContent={<LeftContent />} rightContent={<RightContent />}/>
}

function LeftContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>
				Key benefits
			</h2>

			<ul className={style.highlightedList}>
				<li>-	Fast-tracked residency within 3 weeks</li>
				<li>-	Citizenship approval within 6 months</li>
				<li>-	Dual citizenship allowed</li>
				<li>-	Montenegro is listed for incorporation in the European Union by 2025.</li>
				<li>-	Visa free travel to over 116 countries</li>
			</ul>
		</div>
	)
}

function RightContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>
				Citizenship by Investment Program
			</h2>
			<p className={style.paragraph}>
				Montenegro is a hub of innovation, growth, and development. Also, the country features some of the most stunning sceneries worldwide. The strategic position, political stability, and economic independence make the country a solid choice for investors as it already hosts some of the greatest industries globally.
			</p>                
			<p className={style.paragraph}>
				As a young, stable democracy with an open economy, investing in Montenegro is participating in a growing economy. You will enjoy taxation benefits in a business-friendly environment and a leading country in terms of tourism growth.
			</p>

			<p className={style.paragraph}>
				Montenegro is an attractive destination for a number of reasons:
			</p>
			<ul className={style.list}>
				<li>-	You are not required to have proficiency in Montenegrin or any other official languages.</li>
				<li>-	No obligation to renounce your current citizenship. The program allows for dual citizenship.</li>
				<li>-	You can include spouse and children below 18 years.</li>
				<li>-	Montenegro is a member of NATO.</li>
				<li>-	The strategic location of Montenegro.</li>
				<li>-	The country is a leading tourism destination globally. </li>
			</ul>

			<h2 className={style.littleHeader}>
				Citizenship Requirements
			</h2>
			<p className={style.paragraph}>
				Immigrants seeking to obtain Montenegro citizenship via the investment option must meet the following mandatory minimum requirements:
			</p>
			<ul className={style.list}>
				<li>-	Be a non-EU national.</li>
				<li>-	Have a valid Passport or another acceptable official travel document.</li>
				<li>-	Have a clear criminal history.</li>
				<li>-	Be an applicant via the authorized agents affiliated to this program.</li>
				<li>-	Obtain and submit all the required documents and meet the needed background checks and verifications.</li>
				<li>-	You can confirm the origin of your funds.</li>
				<li>-	Above 18 years.</li>
			</ul>

			<h2 className={style.littleHeader}>
				Investment Options
			</h2>
			<p className={style.paragraph}>
				All applicants seeking Montenegro citizenship by investment are required to make a 100,000 EUR donation to the government fund.
			</p>
			<p className={style.paragraph}>
				The applicant is also bound to invest in approved real estate projects. The required minimum investment depends on the location of the project:
			</p>

			<ul className={style.list}>
				<li>- <b>250,000 EUR</b> investment is required if project is based in the under-developed Northern region of the country OR</li>
				<li>- <b>450,000 EUR</b> investment is required if project is based in the rest of Montenegro. </li>
			</ul>
		</div>
	)
}

export default Montenegro;
