
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`What is the benefit of offshore banking?`}</h1>
    <p>{`Offshore banking provides many benefits. First and foremost, you will keep your money in a secure and central location, connected to your local accounts.`}</p>
    <p>{`Potentially, you might also take advantage of tax-efficient savings and investments in various currencies. However, any tax benefits would depend on the circumstances. Contrary to what you get at local banks in most developing nations, there are international banks around the world that can offer higher interest rates for your investments.`}</p>
    <p>{`Diversifying your bank accounts is always a great option. You will end up with backup accounts with a range of different interest rates, and even foreign currencies.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;