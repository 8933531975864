import React from 'react';
import { Link } from 'react-router-dom';
import serviceOverviewData from './ServiceData.js';
import style from './serviceOverview.module.scss';
//import {ReactComponent as Arrow} from '../../assets/images/vector/arrow.svg'

//creates serviceOverview element
function Service(props) {
	return (
		<Link className={style.serviceContainer} to={props.data.link}>
			<div className={style.image}>{props.data.image}</div>
			<h2 className={style.littleHeader}>{props.data.title}</h2>
			<p className={style.paragraph}>{props.data.overview.text}</p>

			{/* <div className={style.learnMore}>
				<p className={style.paragraph}>Learn more</p>
				<Arrow />
			</div> */}
		</Link>
	);
}
//creates grid of serviceOverview elements
function ServiceOverview() {
	let ServicesGrid = serviceOverviewData.map((props, index) => (
		<Service data={props} id={index} key={index} />
	));

	ServicesGrid.unshift(
		<div key={-1} className={style.first}>
			<h2 className={style.littleHeader + ' ' + style.firstMargins}>
				{'Discover Our Services'}
			</h2>
			<p className={style.discoverParagraph + ' ' + style.firstMargins}>
				{
					'Can’t find what you’re looking for? We know that it is not easy to navigate in the offshore world, and we do not believe that there exists a one-fits-all solution.'
				}
			</p>
			<div></div>
			<Link to={'/contact'} className={style.firstMargins}>
				<button className={style.button}>Let's talk</button>
			</Link>
		</div>
	);
	ServicesGrid.push(
		<div key={-2} className={style.last}>
			<p className={style.paragraph + ' ' + style.lastMargins}>
				{
					'Can’t find what you’re looking for? We know that it is not easy to navigate in the offshore world, and we do not believe that there exists a one-fits-all solution.'
				}
			</p>

			<Link to={'/contact'} className={style.firstMargins}>
				<button className={style.button}>Let's talk</button>
			</Link>
		</div>
	);

	return <div className={style.container}>{ServicesGrid}</div>;
}

export default ServiceOverview;
