import React from 'react';
import style from '../serviceComponent.module.scss'
import ServiceTemplate from 'components/services/serviceTemplate/ServiceTemplate'

function Malta({data, subData}){
	const headerRightContent=
	<div>
		<p className={style.paragraph}>
			Malta offers highly attractive residency and citizenship schemes for foreign nationals willing to invest in the country. The first is the Malta Residency and Visa Program (MRVP), a two-year, renewable permit to reside in Malta. Second is the Malta Individual Investor Program (MIIP), which offers a route to full citizenship of the EU member state.
		</p>
	</div>

	return <ServiceTemplate data={data} subData={subData} headerRightContent={headerRightContent} leftContent={<LeftContent />} rightContent={<RightContent />}/>
}

function LeftContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>
				Key benefits
			</h2>
			<ul className={style.highlightedList}>
				<li>- European Union citizenship including right to work and live in Europe</li>
				<li>- Residency and citizenship options</li>
				<li>- Practically the last citizenship by investment program in European Union</li>
			</ul>
		</div>
	)
}

function RightContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>
				Residence and Visa Program (MRVP)
			</h2>
			<p className={style.paragraph}>
				The MRVP is open to non-EU and EEA citizens. It takes only three months to process and allows successful applicants to stay in Malta for two years, after which time the permit is renewable. Other benefits include:
			</p>
			<ul className={style.list}>
				<li>•	The right to live and settle indefinitely in Malta, with the possibility of applying for a work permit</li>
				<li>•	Naturalization as a Maltese citizen after residing in the country for four years out of six</li>
				<li>•	Travel without a visa throughout the entire EU Schengen zone</li>
				<li>•	No requirement to be in Malta during the residency period</li>
				<li>•	Tax exemption on external income</li>
				<li>•	Ability to add children or a spouse to the permit after the application process and additionally the ability for children to add their children or spouse(s) at a later date (all subject to extra fees and checks)</li>
			</ul>

			<h2 className={style.littleHeader}>
				Residency Requirements
			</h2>
			<p className={style.paragraph}>
				Applicants for either the MRVP or the MIIP must fulfil the following basic requirements:
			</p>
			<ul className={style.list}>
				<li>•	Have an annual income of EUR 100,000 or more, OR capital of EUR 500,000 or more and be able to prove they are economically self-sufficient</li>
				<li>•	Aged 18 years or above </li>
				<li>•	Must be a valid passport holder </li>
				<li>•	Hold a full and comprehensive health insurance policy covering all dependents </li>
			</ul>
			<p className={style.paragraph}>
				Each permit also has specific investment and property requirements. MRVP requires the following:
			</p>
			<ul className={style.list}>
				<li>•	A minimum investment of EUR 250,000 in approved instruments (typically government bonds) held for at least five years</li>
				<li>•	Purchase of a property in Malta valued at least EUR 320,000, or EUR 270,000 in Gozo or the South of Malta, OR;</li>
				<li>•	Rent a property for an amount of EUR 12,000 per year, or EUR 10,000 in Gozo or the South of Malta</li>
				<li>•	Whether purchased or rented, all property must be for the sole use of the applicant, with subletting not permitted</li>
			</ul>

			<h2 className={style.littleHeader}>
				Individual Investor Program (MIIP) for Citizenship
			</h2>
			<p className={style.paragraph}>
				Successful applicants to the MIIP will enjoy full citizenship in Malta: a well-respected, politically and economically stable European Union member country. The many benefits of this include:
			</p>
			<ul className={style.list}>
				<li>•	The right to own a second home in Malta </li>
				<li>•	Visa-free travel to more than 160 countries worldwide, including the United States</li>
				<li>•	The right to establish themselves and their businesses in all EU member states, and Switzerland </li>
			</ul>

			<h2 className={style.littleHeader}>
				Citizenship Requirements
			</h2>
			<p className={style.paragraph}>
				In addition to the basic requirements set out for residency under Malta Residence and Visa Program, those applying for the MIIP must meet the following investment and property criteria:
			</p>
			<ul className={style.list}>
				<li>•	Invest a minimum of EUR 650,000 in approved investments, (typically government bonds) and hold them for at least five years</li>
				<li>•	The spouse of the applicant and all children under the age of 18 also have to each invest EUR 25,000</li>
				<li>•	Dependents aged 55 years and above, as well as unmarried children aged between 17 and 26, must each invest EUR 50,000 </li>
				<li>•	Applicants must submit EUR 10,000 of the above when applying for citizenship and pay the remaining balance after approval</li>
				<li>•	Purchase a property in Malta valued at a minimum of EUR 350,000, or EUR 270,000 in Gozo or south of Malta, OR;</li>
				<li>•	Rent a home for an amount of at least EUR 16,000 per year, or EUR 10,000 per year in Gozo or south of Malta</li>
				<li>•	The applicant must hold either purchased or rented property for at least five years</li>
				<li>•	All property must be for the sole use of the applicant, with subletting not permitted</li>
			</ul>
			<p className={style.paragraph}>
				Understandably, the government of Malta wishes to attract only the highest quality applicants to its residency and citizenship programs. As such, those applying for either the MRVP or the MIIP can expect to be subject to strict due diligence checks and scrutiny. Those granted a permit must also travel to Malta to collect it, along with their dependents.
			</p>
		</div>
	)
}

export default Malta;
