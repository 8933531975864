import React, { useEffect } from 'react'
import style from './navigationMenu.module.scss'

import HeaderLinks from './HeaderLinks'
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'
import SocialMediaLinks from 'components/navigation/socialMediaLinks/SocialMediaLinks'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { ReactComponent as CloseImage } from 'assets/images/vector/close-white.svg'

function NavigationMenu({ showMenu, setShowMenu }) {
    var visibility = style.hide;

    if (showMenu) {
        visibility = style.show;
    }

    const { width } = useWindowDimensions();
    let targetElement = document.querySelector('#menu');

    useEffect(() => {
        if (width <= 1024 && showMenu)
            disableBodyScroll(targetElement)
        else if (width > 1024)
            enableBodyScroll(targetElement)
    }, [showMenu, width, targetElement])

    return (
        <>
            <div onClick={() => {
                enableBodyScroll(targetElement)
                setShowMenu(false)
            }} className={style.overlayBackground + ' ' + visibility}></div>

            <div id="menu" className={style.flyoutMenu + ' ' + visibility}>
                <div className={style.navigationHeader}>
                    <div className={style.media}>
                        <SocialMediaLinks white={true} />
                    </div>
                    <span onClick={() => {
                        enableBodyScroll(targetElement)
                        setShowMenu(false)
                    }} className={style.close}>
                        <CloseImage />
                    </span>
                </div>

                <div className={style.links}>
                    <HeaderLinks callback={() => {
                        enableBodyScroll(targetElement)
                        setShowMenu(false)
                    }} />
                </div>
            </div>
        </>
    )
}

export default NavigationMenu