
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Can I be a tax resident in 2 countries?`}</h1>
    <p>{`Two countries might consider you a tax resident at the same time in some cases. Thus, you could be asked to pay taxes on your total income in both nations. Fortunately, the majority of countries hold double tax agreements. These agreements usually provide the rules to determine which of the two countries should treat you as resident.`}</p>
    <p>{`In a case of the tax treaty not providing a solution or when your situation is incredibly complicated, contact us to clarify your situation.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;