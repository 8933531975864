import React, { useEffect } from 'react';
import style from './home.module.scss';
import { Link, useLocation } from 'react-router-dom';

import MetaTags from 'components/MetaTags'
import ServiceOverview from "components/services/ServiceOverview";
import BlogCarousel from 'components/blog/BlogCarousel';
import { ReactComponent as BankImage } from 'assets/images/vector/offshorebank.svg';

function Home() {
	const location = useLocation()
	const metaData = {
		ogDescription: "Opening offshore bank accounts for businesses and individuals",
		keywords: "offshore bank account legal company help"
	};

	// Used for scrolling to service overview
	useEffect(() => {
		if (location.hash) {
			let elem = document.getElementById(location.hash.slice(1))
			if (elem) {
				elem.scrollIntoView({ behavior: "smooth" })
			}
		} else {
			window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
		}
	}, [location,])

	return (
		<div className={style.page}>
			<div className="application">

				<MetaTags metaData={metaData} />

			</div>
			<MainView />

			<div id="services">
				<ServiceOverview />
			</div>

			<h1 className={style.littleHeader}>Blog</h1>
			<BlogCarousel />
		</div>
	);
}

function MainView() {
	return (
		<div className={style.container}>
			<div className={style.bankImage}>
				<BankImage />
			</div>
			<div className={style.text}>
				<h1 className={style.header}>Helping to open offshore bank accounts</h1>
				<p className={style.paragraph}>
					Given our experience and good relationships with various financial institutions around the globe, we can not only introduce you to the best financial institution for your needs but also lead you through the process of opening a bank account.				</p>
				<Link to="/contact"><button className={style.button + " simpleButton"}>Contact us</button></Link>
			</div>
		</div>
	);
}


export default Home;
