import React, { useState } from 'react'
import style from './faqInsideTemplate.module.scss'

import FaqHeader from './FaqHeader'
import FaqNotFound from './FaqNotFound'
import FaqRouteTree from './FaqRouteTree'
import ContentTemplateMobileBreakpoint from 'components/templates/ContentTemplateMobileBreakpoint'

import { ReactComponent as ArrowDown } from 'assets/images/vector/arrow-down.svg'
import { ReactComponent as ArrowUp } from 'assets/images/vector/arrow-up.svg'

function FaqInsideTemplate({ leftSideTitle, leftSideContent, rightSideContent, routeList, takeTitleFromRouteList = true }) {
    const [showLeftSide, setShowLeftSide] = useState(false)

    const leftSide =
        <>
            <div className={style.popular}>
                <div className={style.popularTitle}>
                    {leftSideTitle}
                </div>
                {leftSideContent}
            </div>

            <div className={style.popularMobile}>
                <FaqRouteTree routeList={routeList} />
                <div className={style.popularTitleMobile} onClick={() => setShowLeftSide(!showLeftSide)}>
                    {leftSideTitle}
                    {
                        showLeftSide ?
                            <ArrowUp className={style.popularArrow} /> : <ArrowDown className={style.popularArrow} />
                    }
                </div>
                {showLeftSide ? leftSideContent : null}
            </div>
        </>

    const rightSide =
        <>
            <div className={style.hideOnMobile}>
                <FaqRouteTree routeList={routeList} />
            </div>

            {takeTitleFromRouteList &&
                <h3 className={style.littleHeader}>
                    {routeList[routeList.length - 1].name}
                </h3>}

            {rightSideContent}
        </>

    return (
        <>
            <FaqHeader />
            <ContentTemplateMobileBreakpoint leftSideContent={leftSide} rightSideContent={rightSide} />
            <FaqNotFound />
        </>
    )
}

export default FaqInsideTemplate