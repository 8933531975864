import React from 'react'
import style from './infoTemplate.module.scss'

import { ReactComponent as Icon } from 'assets/images/vector/info.svg'

function InfoTemplate({ content }) {
    return (
        <div className={style.container}>
            <Icon className={style.icon} />

            {content}
        </div>
    )
}

export default InfoTemplate