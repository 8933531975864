
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`What is offshore income?`}</h1>
    <p>{`Income is considered `}<em parentName="p">{`offshore income`}</em>{` if it comes from a territory outside your tax residency country. For instance, if you are running a local business that conducts business activity in an offshore jurisdiction, the income from that jurisdiction would be considered 'offshore income'.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;