
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`What is a Controlled Foreign Corporation (CFC)?`}</h1>
    <p>{`A corporate entity is considered a controlled foreign corporation (CFC) if it is registered or conducts operations in a different country or jurisdiction than the controlling owners' residency.`}</p>
    <p>{`The laws of controlled foreign corporations work alongside tax treaties – they dictate how taxpayers should report on their foreign income. A controlled foreign corporation is beneficial for companies when the cost of setting up a business, foreign branches, or partnerships in a foreign country is lower even after the tax implications – or when the global exposure could help the company grow.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;