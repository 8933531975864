
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`What are non-CRS countries?`}</h1>
    <p>{`The list of Common Reporting Standard (CRS) members is always changing. Year after year, more countries join this agreement. Most popular non-CRS countries are Armenia, Cambodia, United States (consider FACTA rules), Ukraine, Dominican Republic, Republic of Georgia, Kazakhstan, Puerto Rico, and more.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;