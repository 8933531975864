
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`In what countries can I get a second passport?`}</h1>
    <p>{`If you are lucky enough to have ancestors from places like Lithuania, Spain or Germany, then obtaining a second passport is a very straightforward process, assuming that you can document your family ancestry.`}</p>
    <p>{`However, for most people who are not part of this fortunate bloodline club, obtaining dual citizenship or a second passport requires time, money, and flexibility.`}</p>
    <p>{`There are many countries where you can get a second citizenship, but the most popular would be Vanuatu, Saint Kitts and Nevis, Dominica, St. Lucia, and Malta.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;