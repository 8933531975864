
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`What is EMI account?`}</h1>
    <p>{`By definition, EMI account is an account held in an Electronic Money Institution, in other words – a digital bank. It is a fintech institution similar to an offshore bank. However, the two differ in their operation models – EMI operates online only. You can use the EMI account to send and receive your money and use debit cards. In reality, it's like an ordinary bank.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;