
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import authors from 'components/blog/BlogAuthorData';
export const metadata = {
  author: authors.gediminas,
  headline: "What Is Permanent Establishment?",
  date: "2020 12 29",
  slug: "what-is-permanent-establishment",
  description: "Permanent establishment is a term that governments use to tax foreign companies that operate in their nation. Being defined as a permanent establishment can lead to heavy taxes being levied on your company, so it is important to know the tax codes of jurisdiction that your business is operating in.",
  imageAlt: 'office meeting space',
  metaTags: {
    title: "What Is Permanent Establishment (PE)? | Offshore Bank Accounts Blog",
    description: "What Is Permanent Establishment (PE)? It is a term that governments use to tax foreign companies that operate in their nation. It is important to know the tax codes of jurisdiction that your business is operating in.",
    ogDescription: "Permanent Establishment is a term that governments use to tax foreign companies that operate in their nation. It is important to know the tax codes of jurisdiction that your business is operating in."
  }
};

const layoutProps = {
  metadata
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When operating an offshore business, you may encounter the term `}<em parentName="p">{`permanent establishment.`}</em>{` Simply, permanent establishment (PE) is a way for jurisdictions to tax foreign businesses that operate in their jurisdiction.`}</p>
    <p>{`Before we get into the details, remember that every country has slightly different criteria for what defines permanent establishment and levies taxes in different ways.`}</p>
    <p>{`Despite this, some aspects and definitions of permanent establishment apply in most countries.`}</p>
    <h2>{`What constitutes a permanent establishment?`}</h2>
    <p>{`If your company is engaged in business in a foreign country in a stable, consistent, and ongoing manner, it may have a permanent establishment there.`}</p>
    <p>{`Because your corporation’s activity generated revenue in a foreign country, that nation will want to tax the business. If your company meets that jurisdiction’s rules for permanent establishment, then your company may be taxed there.  `}</p>
    <p>{`Many nations follow the `}<a parentName="p" {...{
        "href": "https://www.oecd.org/tax/beps/beps-actions/action3/"
      }}>{`standards`}</a>{` of The Organisation For Economic Cooperation and Development (OECD) when defining permanent establishment. `}</p>
    <p>{`Their criteria for PE are focused on the geographic location of where the corporation is engaging in business activities. In short, if a foreign corporation has a fixed place of business in a jurisdiction, it could be considered a permanent establishment. `}</p>
    <p>{`Let’s explore what a fixed place of business is and what it means for you:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: This means that it is a relatively permanent location where employees work.`}</li>
      <li parentName="ul"><strong parentName="li">{`Place`}</strong>{`: A physical facility that belongs to your company and is used for the company.`}</li>
      <li parentName="ul"><strong parentName="li">{`Business`}</strong>{`: Your business activity is conducted at this location.`}</li>
    </ul>
    <h2>{`Fixed place of business and other factors for PE`}</h2>
    <p>{`A fixed place of business can include office space, a branch of a company, manufacturing plants, or even a mine. (Note: Construction projects are a subset of the fixed place of business but have different requirements and regulations). `}</p>
    <p>{`Employee activity, especially activity that results in revenue, is also relevant to determining if the company will be considered a permanent establishment.`}</p>
    <p>{`Generally, employees, especially salesmen, must be active on a recurring schedule, not just visiting once or twice, for the company to be considered a permanent establishment.`}</p>
    <p>{`Lastly, the time frame of the business activities and the amount of control that the parent company has over the local employees can also trigger permanent establishment.`}</p>
    <p>{`Overall, every nation and jurisdiction has its own set of standards and regulations for what actions warrant permanent establishment status. But these general principles should give you an idea of what permanent establishment is and how it might affect your company’s taxes.`}</p>
    <h2>{`What kind of taxes does permanent establishment trigger?`}</h2>
    <p>{`The taxes levied can be heavy as they often include VAT, sales tax, and corporate tax. Because of this, foreign corporations need to seriously consider the ramifications of being labeled as a `}<em parentName="p">{`permanent establishment.`}</em></p>
    <p>{`If the business activity results in locally generated revenue, the jurisdiction may levy local corporate tax rates on the company at a local rate. `}</p>
    <p>{`This could be especially strenuous if the company is based in a high-tax country. `}</p>
    <p>{`For example, the US will levy corporate taxes on any company that meets their definition of permanent establishment. Currently, the corporate tax rate in the US is 21%. `}</p>
    <h2>{`Limiting permanent establishment risk`}</h2>
    <p>{`Though the risk of permanent establishment and its potential tax implications can be intimidating, you have a few things you can do to limit your chances of receiving this label. `}</p>
    <p>{`Consider speaking to an expert. They can advise on the permanent establishment rules for a given country, the likelihood of you receiving this status, and what the taxes are in that jurisdiction.`}</p>
    <p>{`Without understanding a country’s rules, it’s easy to accidentally fall into permanent establishment and incur hefty tax bills as a result.`}</p>
    <p>{`You should also know how much time you or your employees will spend in the jurisdiction. Depending on the country, they may have a certain time threshold that could make your business a permanent establishment there. `}</p>
    <p>{`In some cases, incorporating a company or subsidiary in a foreign jurisdiction can be a good move. This often eliminates concerns about permanent establishment status and clarifies the entity’s tax status. `}</p>
    <p>{`Staying up to date with new permanent establishment regulations is important too. In the future, more countries will likely start looking for ways to apply PE rules to digital and online companies. `}</p>
    <h2>{`How do treaties and agreements affect permanent establishment?`}</h2>
    <p>{`It depends on the exact treaty as some treaties exclude certain activities from triggering permanent establishment while others expand the power of nations to tax foreign businesses.`}</p>
    <p>{`For example, in 2017 the United States enacted new legislation called the Tax Cuts and Jobs Act. This made the U.S tax code more in line with the OECD recommendations, which made the permanent establishment regulations stricter.`}</p>
    <p>{`Alternatively, many tax treaties have permanent establishment exceptions for facilities that are solely for the storage, display, or delivery of goods.`}</p>
    <p>{`These exceptions and agreements between nations can be key for understanding how permanent establishment will affect your business.`}</p>
    <p>{`However, as mentioned, each treaty will have its own nuance, specifications, and agreements, so be sure to consult a tax advisor before making significant business decisions.`}</p>
    <h2>{`What does it all mean?`}</h2>
    <p>{`Permanent establishment is a term that governments use to tax foreign companies that operate in their nation. `}</p>
    <p>{`Being defined as a permanent establishment can lead to heavy taxes being levied on your company, so it is important to know the tax codes of jurisdiction that your business is operating in.`}</p>
    <p>{`Not only do you need to worry about being taxed in the country you do business in, but you also could be taxed by the country that you live in. `}</p>
    <p>{`Permanent establishment regulations and `}<a parentName="p" {...{
        "href": "/blog/what-is-controlled-foreign-corporation"
      }}>{`controlled foreign corporations`}</a>{` are interconnected issues and merely moving your business offshore will not necessarily be sufficient in reducing the amount you pay in taxes.`}</p>
    <p>{`Because the `}<a parentName="p" {...{
        "href": "/blog/five-reasons-to-start-offshore-company"
      }}>{`benefits of offshore companies`}</a>{` are significant, the complexity should not stop you from opening one. But we highly recommend consulting a professional when going through the process. Poor planning or improper structuring could lead to wasted time, lost money, and an excessive tax burden. `}</p>
    <p>{`Our team specializes in creating solutions to complex offshore and structuring situations. We would be happy to help you navigate these issues. Set up a free consultation `}<a parentName="p" {...{
        "href": "/contact"
      }}>{`here`}</a>{`.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;