
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Can a non-resident open a bank account?`}</h1>
    <p>{`If you would like to open a bank account in a country that you have no ties with, you need to know the requirements of opening an account, as well as other ins and outs to save your time and money.`}</p>
    <p>{`Being able to open an account as a non-resident depends on several factors, such as your professional experience, citizenship, tax residency, and sources of income.`}</p>
    <p>{`If you are in need of a bank account in a foreign country, please `}<a parentName="p" {...{
        "href": "https://offshorebankaccounts.com/media/pre-approval-business.pdf"
      }}>{`download our pre-approval form`}</a>{` for a business account. After filling the form, send it to our team at `}<a parentName="p" {...{
        "href": "mailto:hello@offshorebankaccounts.com."
      }}>{`hello@offshorebankaccounts.com.`}</a>{` For a personal account, send us an email with your full name, countries of citizenship and tax residency, and information about the source of your wealth. Our team will get back to you and present the possible options for opening an account in the desired jurisdiction.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;