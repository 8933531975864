import React from 'react';
import style from './legal.module.scss';
import MetaTags from 'components/MetaTags'
import ContentTemplate from 'components/templates/ContentTemplate'
import { Link } from 'react-router-dom';

function CookiePolicy() {
	const metaData = {
		title: `Cookie Policy | Offshore Bank Accounts`, 
		ogDescription: "Opening offshore bank accounts for businesses and individuals",
	};

	const content=
	<>
		<div className={style.littleHeader}>
		1. What are cookies
		</div>
		<div className={style.paragraph}>
		Cookies are small text files that are automatically placed on your computer, mobile phone or other device once you enter our website <Link to="/">https://offshorebankaccounts.com/</Link> (hereinafter the <p className={style.paragraphMedium}>"Website"</p>). They are stored by your internet browser and may contain basic information about your device or internet use.
		</div>
		<div className={style.paragraph}>
		As explained further in the Cookie Policy (the <p className={style.paragraphMedium}>"Cookie Policy"</p>), cookies help us to enhance your experience when using the Website, i.e., to remember your browsing habits, actions and settings, and customise some other functions. There are also cookies which allow us to monitor the frequency of your visits and collect general statistical information about the Website's traffic, as well as deliver personalised content and track the effectiveness of our ad campaigns on third party websites (if applicable). Different classifications of cookies that are used on the Website may be available and some cookies may contain features from different cookie types.
		</div>

		<div className={style.littleHeader}>
		2. What type of cookies do we use in our Website?
		</div>
		<div className={style.paragraph}>
		The main categories of cookies we use in our Website:
		</div>

		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>A. Necessary cookies</p>
		</div>
		<div className={style.paragraph}>
		Necessary cookies are essential for the Website's navigation, performance and functionality. These cookies are required in order to deliver information and services to you. For example, necessary cookies are used to display content, allow you to navigate through the Website, etc. Without these cookies, some parts of our Website cannot function properly. Please note that necessary cookies are essential and do not require consent.
		</div>

		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>B. Preference cookies</p>
		</div>
		<div className={style.paragraph}>
		Preference cookies enable our Website to remember information that alters the way the Website looks or functions. For example, preference cookies could help to show you the content of the Website based on your current location.
		</div>
		
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>C. Analytics cookies</p>
		</div>
		<div className={style.paragraph}>
		Analytics cookies help us to understand how you use and interact with the Website and allow us to make it better. These cookies collect information and report Website usage statistics without personally identifying individual Website’s visitor. For example, these cookies collect information about how visitors move around the Website when they are using it and help us to identify recurring errors that they may encounter. This allows us to provide necessary information for our users and to update the Website according to their interests.
		</div>

		<div className={style.littleHeader}>
		3. Third party cookies
		</div>
		<div className={style.paragraph}>
		These cookies are set on our Website by someone other than the Website’s owner for the purposes such as collecting information on user behaviour, demographics, or targeted marketing, etc. By setting their cookies on our website third parties may also use other partners for cookie matching which may implement their cookies, as well. We do not have control over the placement of cookies by other websites, therefore, for more information about third party cookies and partners engaged by third-party you should look at third-party's website (i.e. third-party's privacy policy).
		</div>

		<div className={style.littleHeader}>
		4. Cookies we use
		</div>

		<div className={style.tableContainer}>		
			<table className={style.table}>
				<thead>
					<tr>
						<th style={{width: "80px"}}>Name</th>
						<th style={{width: "70px"}}>Source</th>
						<th style={{width: "230px"}}>Purpose</th>
						<th style={{width: "90px"}}>Creation time</th>
						<th style={{width: "70px"}}>Expiry</th>
						<th style={{width: "150px"}}>Data used</th>
					</tr>
					<tr>
						<th colSpan="6">Necessary cookies</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>__cfduid</td>
						<td>CloudFare</td>
						<td>These cookies are used by Cloudflare to support performance optimisation and security, remember users’ settings for authentication and analytics.</td>
						<td>Upon the entry to the website</td>
						<td>1 year</td>
						<td>Unique ID, duration</td>
					</tr>
					<tr>
						<td>1P_JAR</td>
						<td rowSpan={11}>Google</td>
						<td rowSpan={11}>These cookies are used for the functionality of Google Recaptcha which helps the company to detect abusive traffic on its website without any user friction and hereby protects the website from spam and abuse. Google Recaptcha uses an advanced risk analysis engine and adaptive challenges to keep automated software from engaging in abusive activities on the company's site. Therefore, the use of Google Recaptcha is indispensable for the security of the website. These cookies may also be used for other purposes set by Google.</td>
						<td rowSpan={11}>Upon the entry to the contact us page</td>
						<td>1 month</td>
						<td rowSpan={11}>User's preferences and other information such as the preferred language, the number of search results to be displayed on the page, result if the Google SafeSearch should be activated or not. For more information about usage you may check Google terms of use and privacy policy.</td>
					</tr>
					<tr>
						<td>CONSENT</td>
						<td>20 years</td>
					</tr>
					<tr>
						<td>ANID</td>
						<td>2 years</td>
					</tr>
					<tr>
						<td>APISID</td>
						<td>3 years</td>
					</tr>
					<tr>
						<td>HSID</td>
						<td>3 years</td>
					</tr>
					<tr>
						<td>NID</td>
						<td>2 years</td>
					</tr>
					<tr>
						<td>SAPISID</td>
						<td>2 years</td>
					</tr>
					<tr>
						<td>SID</td>
						<td>2 years</td>
					</tr>
					<tr>
						<td>SIDCC</td>
						<td>6 months</td>
					</tr>
					<tr>
						<td>DV</td>
						<td>1 hour</td>
					</tr>
					<tr>
						<td>OTZ</td>
						<td>1 month</td>
					</tr>
					<tr>
						<td>__livechat</td>
						<td>LiveChat</td>
						<td>Necessary for the functionality of the website's chat-box function.</td>
						<td>Upon the entry to the website</td>
						<td>2 years</td>
						<td>Contains information about user names, languages, number of chat sessions, time for the last visit, number of side views, number of sessions downloaded by the chat solution, who the user was serviced by, session ID, potentially goal achievements</td>
					</tr>
					<tr>
						<td>offshore-consent</td>
						<td>Offshore Bank Accounts</td>
						<td>Stores the user consent to cookies</td>
						<td>When a user accepts the cookie consent</td>
						<td>150 days</td>
						<td></td>
					</tr>
				</tbody>
				<thead>
					<tr>
						<th colSpan="6">Preference cookies</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>CASID</td>
						<td>LiveChat</td>
						<td>Allows the website to recognise the visitor, in order to optimize the chat-box functionality.</td>
						<td>Upon the entry to the website</td>
						<td>Session</td>
						<td></td>
					</tr>
				</tbody>
				<thead>
					<tr>
						<th colSpan="6">Analytics cookies</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>_ga</td>
						<td rowSpan={4}>Google Analytics</td>
						<td>Used to distinguish users</td>
						<td rowSpan={4}>Upon the entry to the website</td>
						<td>24 hours</td>
						<td>Unique ID, duration, time, visited pages, language, device (name, model, version, browser, resolution and other ), source of traffic, general anonymized demographics and interest groups</td>
					</tr>
					<tr>
						<td>_gat</td>
						<td>Used to throttle request rate</td>
						<td>1 minute</td>
						<td>Unique ID, duration</td>
					</tr>
					<tr>
						<td>_gid</td>
						<td>Used to distinguish users</td>
						<td>24 hours</td>
						<td>Unique ID, duration, time, visited pages, language, device (name, model, version, browser, resolution and other ), source of traffic, general anonymized demographics and interest groups	</td>
					</tr>
					<tr>
						<td>_gat_gtag_[property-id]</td>
						<td>Used to throttle request rate</td>
						<td>1 minute</td>
						<td>Unique ID, duration</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div className={style.littleHeader}>
		5. How to manage your cookie preferences?
		</div>
		<div className={style.paragraph}>
		You can manage your cookie preferences through the browser which you are using settings. Each browser is different, but at most cases you should be able to change the settings via selection of “Help”, “Tools” or “Edit” menus in your browser. Your device's operating system may also have additional cookie controls, so you can change them in order to refuse the use of cookies.
		</div>
		<div className={style.paragraph}>
		Please note that disabling a cookie or category of cookies does not delete the cookie from your browser unless manually completed through your browser function.
		</div>
		<div className={style.paragraph}>
		Also your device's operating system may have additional cookie controls, so you can change them in order to refuse the use of cookies.
		</div>

		<div className={style.littleHeader}>
		6. Miscellaneous
		</div>
		<div className={style.paragraph}>
		The Website is administrated by OBA Advisory FZE LLC, license number 3179, registered address at A-5801-49 - Flamingo Villas, Ajman, United Arab Emirates.
		</div>
		<div className={style.paragraph}>
		If you have any questions regarding this Cookie Policy you can contact us via e-mail: <a href="mailto:hello@offshorebankaccounts.com">hello@offshorebankaccounts.com</a>.
		</div>
		<div className={style.paragraph}>
		Please be aware that we keep the right to update the current Cookie Policy from time to time in order to comply with the changes in legislation or according to our business needs or based on some other relevant reasons. All changes shall come into force upon posting the revised Cookie Policy on the Website and it is your own responsibility to get acknowledged with it. Your continued use of the Website following any revisions to the Cookie Policy will constitute your acceptance of such changes.
		</div>
	</>

	return (
		<>
			<MetaTags metaData={metaData}/>
			<ContentTemplate leftSideContent={<h1 className={style.header}>Cookie Policy</h1>} rightSideContent={content}/>
		</>
	);
}
export default CookiePolicy;
