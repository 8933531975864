
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Which country is tax-free?`}</h1>
    <p>{`There are many countries without taxes, so-called true tax-havens. However, tax laws around the world have changed drastically for many westernized countries as an attempt to prevent assets from leaving the country.`}</p>
    <p>{`You can live a tax-free an pay no income tax in countries like The Bahamas, Vanuatu, Nauru, Oman, Qatar, Panama, Saint Kitts and Nevis, United Arab Emirates, Monaco, Bahrain, Brunei, Cayman Islands, Kuwait, Maldives, and more.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;